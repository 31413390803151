import { ReactNode } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

interface IProps {
  label?: string;
  children?: ReactNode;
  insideGrid?: boolean;
}

const InputContainerForForm = ({ label, children, insideGrid = true }: IProps) => {
  return (
    <Box className='repnotes-input-container' position='relative'>
      <Grid container>
        <Grid
          item
          className='repnotes-input-label-container'
          xs={4}
          style={{
            textAlign: "right",
            paddingRight: "15px",
            paddingTop: "15px",
          }}
        >
          <Typography
            style={{
              padding: "3px 0",
              fontSize: 12,
              fontWeight: 700,
              color: "#272B75",
            }}
          >
            {label}
          </Typography>
        </Grid>
        {insideGrid ? (
          <Grid item xs={8}>
            {children}
          </Grid>
        ) : (
          children
        )}
      </Grid>
    </Box>
  );
};

export default InputContainerForForm;
