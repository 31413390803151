// State Interfaces

export type PriceAdjTermType =
  | "PREPAID"
  | "COD"
  | "<30 Days"
  | "30-60 Days"
  | "60-90 Days"
  | "90-120 Days";

export interface PriceAdjTermsBase {
  terms: PriceAdjTermType;
  priceAdjustment: number;
  priceAdjustmentType: "amount" | "percentage";
  isActive: boolean;
}
export interface PriceAdjTerms extends PriceAdjTermsBase {
  id: string;
  companyId: string;
  dateCreated: string;
  dateUpdated: string;
}

export type PriceAdjTermStatus = "idle" | "success" | "loading" | "failed";
export type PriceAdjTermError = string | null;
export type PriceAdjTermInput = PriceAdjTermsBase;

export const initialTermsInputValue: PriceAdjTermInput = {
  terms: "PREPAID",
  priceAdjustment: 0,
  priceAdjustmentType: "amount",
  isActive: true,
};

export interface PriceAdjTermState {
  status: PriceAdjTermStatus;
  data: PriceAdjTerms[];
  error?: PriceAdjTermError;
  currentCompanyId?: string;
}

// Action Types
export const LOAD_PRICE_ADJ_TERMS_DATA = "priceAdjTerms/fetch";
export const ADD_PRICE_ADJ_TERMS = "priceAdjTerms/add";
export const EDIT_PRICE_ADJ_TERMS = "priceAdjTerms/edit";
export const DELETE_PRICE_ADJ_TERMS = "priceAdjTerms/delete";
export const UPDATE_PRICE_ADJ_TERMS_STATUS = "priceAdjTerms/status";
export const SET_PRICE_ADJ_TERMS_ERROR = "priceAdjTerms/error";
export const SET_PRICE_ADJ_TERMS_CURRENT_COMPANY = "priceAdjTerms/company";
export const RESET_PRICE_ADJ_TERMS_STATE = "priceAdjTerms/reset";

export interface LoadPriceAdjTermsDataAction {
  type: typeof LOAD_PRICE_ADJ_TERMS_DATA;
  payload: PriceAdjTerms[];
}

export interface SetPriceAdjTermsCurrentCompanyAction {
  type: typeof SET_PRICE_ADJ_TERMS_CURRENT_COMPANY;
  payload: { companyId: string };
}

export interface AddPriceAdjTermsAction {
  type: typeof ADD_PRICE_ADJ_TERMS;
  payload: {
    newPriceAdjTerms: PriceAdjTerms;
  };
}

export interface EditPriceAdjTermsAction {
  type: typeof EDIT_PRICE_ADJ_TERMS;
  payload: {
    id: string;
    updatedPriceAdjTerms: PriceAdjTerms;
  };
}

export interface DeletePriceAdjTermsAction {
  type: typeof DELETE_PRICE_ADJ_TERMS;
  payload: { id: string };
}

export interface UpdatePriceAdjTermsStatusAction {
  type: typeof UPDATE_PRICE_ADJ_TERMS_STATUS;
  payload: { status: PriceAdjTermStatus };
}

export interface SetPriceAdjTermsErrorAction {
  type: typeof SET_PRICE_ADJ_TERMS_ERROR;
  payload: { error?: PriceAdjTermError };
}

export interface ResetPriceAdjTermsAction {
  type: typeof RESET_PRICE_ADJ_TERMS_STATE;
}

export type PriceAdjTermsAction =
  | LoadPriceAdjTermsDataAction
  | AddPriceAdjTermsAction
  | EditPriceAdjTermsAction
  | DeletePriceAdjTermsAction
  | UpdatePriceAdjTermsStatusAction
  | SetPriceAdjTermsErrorAction
  | SetPriceAdjTermsCurrentCompanyAction
  | ResetPriceAdjTermsAction;
