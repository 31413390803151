import { MarginResultDetails } from "../../../store/report/marginReport/types";
import Grid from "@material-ui/core/Grid";
import { Line } from "react-chartjs-2";
import map from "lodash/map";

interface RepnotesGraphicalLineProps {
  data: MarginResultDetails;
}

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      stacked: true,
      ticks: {
        callback: function (label: any, index: number, ticks: any) {
          const max = Math.max(...ticks.map((t: any) => t.value));
          return (
            Math.ceil(
              (Number(((parseFloat(label) / max) * 100).toFixed(0)) / 10) * 10
            ) + "%"
          );
        },
        stepSize: 20,
        max: 100,
        min: 0,
        beginAtZero: true,
      },
      barThickness: 18,
    },
    xAxes: [
      {
        stacked: true,
      },
    ],
  },
  maintainAspectRatio: false,
};

export const RepnotesGraphicalLine = (props: RepnotesGraphicalLineProps) => {
  const result = props.data;

  //percentage
  const resultValues = Object.values(result);
  const max = Math.max(...resultValues.map((o: any) => o));
  const resultWithPercentage = Object.values(result).map((e) => {
    const percent = Number(((parseFloat(e) / max) * 100).toFixed(0));
    return percent;
  });

  const data = {
    labels: map(
      Object.keys(result),
      (r: string) => `${r[0].toUpperCase()}${r.slice(1)}`
    ),
    datasets: [
      {
        label: "Margin Percent",
        data: resultWithPercentage,
        backgroundColor: "rgb(68,114,196)",
        borderColor: "rgb(68,114,196)",
        tooltip: {
          callbacks: {
            label: (value: any) => {
              return `Amount: $${
                Object.values(result)[value.dataIndex]
              }  Margin Percent: ${value.dataset.data[value.dataIndex]}%`;
            },
          },
        },
      },
    ],
  };
  return (
    <Grid item xs={12}>
      <Line type data={data} options={options} />
    </Grid>
  );
};
