import { useSelector } from "react-redux";
import { selectCOGSProductCodeOptions } from "../../store/priceAdjustmentFactors/cogs/selectors";
import { usePriceAdjustmentLoader } from "../productManagement/utils/hooks";
import { RepnotesInput } from "./RepnotesInput";

interface PricingFieldProps {
  productCode: string;
  currentSellPrice: number;
  onChange: (productCode: string) => void;
}

const PricingField = (props: PricingFieldProps) => {
  const { productCode, currentSellPrice, onChange } = props;

  const { cogsPrice } = usePriceAdjustmentLoader(productCode);

  const cogsProductCodeOptions = useSelector(selectCOGSProductCodeOptions);

  const isAmount = cogsPrice.type === "amount";

  const newSellPrice = isAmount
    ? currentSellPrice + cogsPrice.value
    : currentSellPrice * (1 + cogsPrice.value / 100);

  return (
    <>
      <RepnotesInput
        id='product-code'
        type='select'
        label='Product Code'
        labelPosition='left'
        options={cogsProductCodeOptions}
        value={productCode}
        onSelectChange={(e) => onChange(e.target.value as string)}
      />
      <RepnotesInput
        disabled
        id='current-sell-price'
        type='text'
        label='Current Sell Price'
        labelPosition='left'
        placeholder='Current Sell Price'
        value={currentSellPrice}
      />
      <RepnotesInput
        disabled
        id='change-in-cogs'
        type='text'
        label='Change in COGS'
        labelPosition='left'
        placeholder='Change in COGS'
        value={cogsPrice.type === "amount" ? cogsPrice.value : cogsPrice.value + "%"}
      />
      <RepnotesInput
        disabled
        id='new-sell-price'
        type='text'
        label='New Sell Price'
        labelPosition='left'
        placeholder='New Sell Price'
        value={newSellPrice.toFixed(2)}
      />
    </>
  );
};

export default PricingField;
