import Box from "@material-ui/core/Box";
import { PriceAdjTerms } from "../../../store/priceAdjustmentFactors/terms/types";
import { TableColumn } from "../../../util/constants";
import { formatDateForTable, usePriceAdjusmentFactorsPermission } from "../../../util/utils";
import { RepnotesActionButton } from "../../common/RepnotesButton";

const ActionButton = ({ itemData }: { itemData: PriceAdjTerms }) => {
  const { canEdit } = usePriceAdjusmentFactorsPermission();
  return (
    <Box display='flex' flexDirection='row' height={35} ml={1}>
      {(canEdit) && <RepnotesActionButton link='price-adj-terms' docId={itemData.id} type='edit' />}
    </Box>
  );
};

const StyledPrice = ({ terms }: { terms: PriceAdjTerms }) => {
  return (
    <span style={{ color: terms.priceAdjustment < 0 ? "red" : "" }}>
      {terms.priceAdjustmentType === "percentage"
        ? terms.priceAdjustment + "%"
        : terms.priceAdjustment.toFixed(2)}
    </span>
  );
};

const TermsColumns: TableColumn[] = [
  {
    id: "terms",
    label: "Terms",
    property: "terms",
    sortable: true,
  },
  {
    id: "priceAdjustment",
    label: "Price Adjustment",
    property: "priceAdjustment",
    sortable: true,
    removePadding: true,
    content: (terms: PriceAdjTerms) => <StyledPrice terms={terms} />,
  },
  {
    id: "dateCreated",
    label: "Date Created",
    property: "dateCreated",
    sortable: true,
    removePadding: true,
    content: (terms: PriceAdjTerms) => formatDateForTable(terms.dateCreated),
  },
  {
    id: "dateUpdated",
    label: "Date Updated",
    property: "dateUpdated",
    sortable: true,
    removePadding: true,
    content: (terms: PriceAdjTerms) => formatDateForTable(terms.dateUpdated),
  },
  {
    id: "action",
    label: "Actions",
    key: "price-adj-terms-actions",
    sortable: false,
    align: "center",
    content: (terms: PriceAdjTerms) => <ActionButton itemData={terms} />,
  },
];

export default TermsColumns;
