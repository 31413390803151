import { ReactNode, CSSProperties } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { RepnotesDefaultButton, RepnotesPrimaryButton } from "./RepnotesButton";
import SlideTransition from "./SlideTransition";

const titleStyles: CSSProperties = {
  margin: 0,
  lineHeight: "25px",
  fontSize: "16px",
  color: "#272B75",
};

const gridStyles: CSSProperties = { paddingTop: 20, paddingBottom: 20, textAlign: "center" };
const spinnerStyles: CSSProperties = {
  width: 40,
  height: 40,
  color: "#000",
  padding: 20,
};

interface ImportDialogProps {
  children?: ReactNode;
  dialogFor?: string;
  open: boolean;
  onClose?: () => void;
  onSave?: () => void;
  isImporting?: boolean;
  progressText?: string;
  isSummary?: boolean;
}

const ImportDialog = (props: ImportDialogProps) => {
  const { children, dialogFor, open, onClose, onSave, isImporting, progressText, isSummary } =
    props;

  const id = dialogFor ? `import-dialog-for-${dialogFor}` : "import-dialog";
  const desc = dialogFor
    ? `dialog showing the imported ${dialogFor} data`
    : "dialog showing the imported data";

  return (
    <Dialog
      open={open}
      TransitionComponent={SlideTransition}
      keepMounted
      fullWidth={true}
      maxWidth='md'
      onClose={onClose}
      aria-labelledby={id}
      aria-describedby={desc}
      style={{ visibility: open ? "visible" : "hidden", zIndex: 9990 }}
    >
      <DialogTitle disableTypography>
        <h6 id={id} style={titleStyles}>
          Data Import
        </h6>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container justify='center' spacing={1}>
          <Grid item xs={12} style={gridStyles}>
            {isImporting ? (
              <Box>
                <Typography>{progressText ? progressText : "Uploading..."}</Typography>
                <CircularProgress style={spinnerStyles} />
              </Box>
            ) : (
              children
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isSummary ? (
          <Box py={1}>
            <RepnotesDefaultButton onClick={onClose}>Close</RepnotesDefaultButton>
          </Box>
        ) : (
          <Box py={1}>
            <RepnotesDefaultButton onClick={onClose}>Cancel</RepnotesDefaultButton>
            <RepnotesPrimaryButton disabled={isImporting} onClick={onSave}>
              Save
            </RepnotesPrimaryButton>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ImportDialog;
