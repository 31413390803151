export type MarketReportTab = "REPORT" | "STATUS";

export type MarketReportFilterOptionsKeys = "productFamilyOptions" | "salesPersonOptions";

export type MarketReportFilterKeys =
  | "selectedCompany"
  | "selectedProductFamily"
  | "selectedSalesperson";

export interface MarketItem {
  period: string;
  salesman: string;
  productFamily: string;
  marketSize: number;
  unitSales: number;
}

export interface FilterOptionItem {
  value: string;
  label: string;
}

export interface MarketReportState {
  // ui
  loading: boolean;
  uploadLoading: boolean;
  activeTab: MarketReportTab;
  dialogOpen: boolean;

  // data
  report: MarketItem[];
  uploadedMarketList: MarketItem[];
  rollingMarketSize: MarketResult;
  rollingShare: MarketResult;
  rollingUnitSales: MarketResult;
  rollingYear: string;
  marketSize: number;
  unitSales: number;

  // filter options
  salesPersonOptions: FilterOptionItem[];
  productFamilyOptions: FilterOptionItem[];

  // selected filters
  selectedCompany: string;
  selectedProductFamily: string;
  selectedSalesperson: string;
}

export interface MarketResult {
  january: number;
  february: number;
  march: number;
  april: number;
  may: number;
  june: number;
  july: number;
  august: number;
  september: number;
  october: number;
  november: number;
  december: number;
}

export const initialMarketResult: MarketResult = {
  january: 0,
  february: 0,
  march: 0,
  april: 0,
  may: 0,
  june: 0,
  july: 0,
  august: 0,
  september: 0,
  october: 0,
  november: 0,
  december: 0,
};

// action types
export const SET_MARKET_REPORT_COMPANY = "marketReport/filter/company/set";
export const SET_MARKET_REPORT_TAB = "marketReport/tab/set";
export const SET_MARKET_REPORT_LOADING = "marketReport/loading/set";
export const SET_MARKET_REPORT_REPORT_DATA = "marketReport/report/set";
export const SET_MARKET_REPORT_STATUS_DATA = "marketReport/status/set";
export const SET_MARKET_REPORT_FILTER_OPTION = "marketReport/filterOptions/set";
export const SET_MARKET_REPORT_SELECTED_FILTER = "marketReport/selectedFilter/set";
export const SET_MARKET_REPORT_IMPORTED_DATA = "marketReport/importedData/set";
export const TOGGLE_MARKET_REPORT_IMPORT_DIALOG = "marketReport/importDialog/toggle";
export const SET_MARKET_REPORT_UPLOADING = "marketReport/uploading/set";

interface SetMarketReportCompanyAction {
  type: typeof SET_MARKET_REPORT_COMPANY;
  payload: { selectedCompany: string };
}

interface SetMarketReportLoadingAction {
  type: typeof SET_MARKET_REPORT_LOADING;
  payload: { loading: boolean };
}

interface SetMarketReportTabAction {
  type: typeof SET_MARKET_REPORT_TAB;
  payload: { tab: MarketReportTab };
}

interface SetMarketReportReportDataAction {
  type: typeof SET_MARKET_REPORT_REPORT_DATA;
  payload: { report: MarketItem[] };
}

interface SetMarketReportStatusDataAction {
  type: typeof SET_MARKET_REPORT_STATUS_DATA;
  payload: { marketResult: MarketResult; rollingYear: string; invoiceAmount: number; cost: number };
}

interface SetMarketReportFilterOptionAction {
  type: typeof SET_MARKET_REPORT_FILTER_OPTION;
  payload: {
    key: MarketReportFilterOptionsKeys;
    optionData: FilterOptionItem[];
  };
}

interface SetMarketReportSelectedFilterAction {
  type: typeof SET_MARKET_REPORT_SELECTED_FILTER;
  payload: {
    key: MarketReportFilterKeys;
    filterValue: string;
  };
}

interface SetMarketReportImportedDataAction {
  type: typeof SET_MARKET_REPORT_IMPORTED_DATA;
  payload: { uploadedMarketList: MarketItem[] };
}

interface ToggleMarketReportImportDialogAction {
  type: typeof TOGGLE_MARKET_REPORT_IMPORT_DIALOG;
  payload: { dialogOpen: boolean };
}

interface SetMarketReportUploadingAction {
  type: typeof SET_MARKET_REPORT_UPLOADING;
  payload: { uploading: boolean };
}

export type MarketReportAction =
  | SetMarketReportCompanyAction
  | SetMarketReportTabAction
  | SetMarketReportReportDataAction
  | SetMarketReportStatusDataAction
  | SetMarketReportLoadingAction
  | SetMarketReportFilterOptionAction
  | SetMarketReportSelectedFilterAction
  | SetMarketReportImportedDataAction
  | ToggleMarketReportImportDialogAction
  | SetMarketReportUploadingAction;
