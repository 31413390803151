import {
  ADD_COGS,
  COGSAction,
  COGSState,
  DELETE_COGS,
  EDIT_COGS,
  LOAD_COGS_DATA,
  RESET_COGS_STATE,
  SET_COGS_CURRENT_COMPANY,
  SET_COGS_ERROR,
  UPDATE_COGS_STATUS,
} from "./types";

const initialState: COGSState = {
  status: "idle",
  data: [],
  error: null,
};

const cogsReducer = (state: COGSState = initialState, action: COGSAction): COGSState => {
  switch (action.type) {
    case UPDATE_COGS_STATUS:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.status !== "failed" ? null : state.error,
      };
    case SET_COGS_ERROR:
      return { ...state, error: action.payload.error };
    case SET_COGS_CURRENT_COMPANY:
      return { ...state, currentCompanyId: action.payload.companyId };
    case LOAD_COGS_DATA:
      return { ...state, data: action.payload, status: "success" };
    case ADD_COGS:
      return { ...state, data: [action.payload.newCOGS, ...state.data] };
    case EDIT_COGS:
      return {
        ...state,
        data: state.data.map((b) => (b.id === action.payload.id ? action.payload.updatedCOGS : b)),
      };
    case DELETE_COGS:
      return {
        ...state,
        data: state.data.filter((b) => b.id !== action.payload.id),
      };
    case RESET_COGS_STATE:
      return initialState;
    default:
      return state;
  }
};

export default cogsReducer;
