// State Interfaces
export interface SourceDeliveryFactorBase {
  priceAdjustment: number;
  priceAdjustmentType: "amount" | "percentage";
  sourceFactory: string;
  deliveryArea: string;
  isActive: boolean;
}
export interface SourceDeliveryFactor extends SourceDeliveryFactorBase {
  id: string;
  companyId: string;
  dateCreated: string;
  dateUpdated: string;
}

export type SourceDeliveryFactorStatus = "idle" | "success" | "loading" | "failed";
export type SourceDeliveryFactorError = string | null;
export type SourceDeliveryFactorInput = SourceDeliveryFactorBase;

export const initialSDFInputValue: SourceDeliveryFactorInput = {
  priceAdjustment: 0,
  priceAdjustmentType: "amount",
  sourceFactory: "",
  deliveryArea: "",
  isActive: true,
};

export interface SourceDeliveryFactorState {
  status: SourceDeliveryFactorStatus;
  data: SourceDeliveryFactor[];
  error?: SourceDeliveryFactorError;
  currentCompanyId?: string;
}

// Action Types
export const LOAD_SOURCE_DELIVERY_FACTOR_DATA = "sourceDeliveryFactor/fetch";
export const ADD_SOURCE_DELIVERY_FACTOR = "sourceDeliveryFactor/add";
export const EDIT_SOURCE_DELIVERY_FACTOR = "sourceDeliveryFactor/edit";
export const DELETE_SOURCE_DELIVERY_FACTOR = "sourceDeliveryFactor/delete";
export const UPDATE_SOURCE_DELIVERY_FACTOR_STATUS = "sourceDeliveryFactor/status";
export const SET_SOURCE_DELIVERY_FACTOR_ERROR = "sourceDeliveryFactor/error";
export const SET_SOURCE_DELIVERY_FACTOR_CURRENT_COMPANY = "sourceDeliveryFactor/company";
export const RESET_SOURCE_DELIVERY_FACTOR_STATE = "sourceDeliveryFactor/reset";

export interface LoadSourceDeliveryFactorDataAction {
  type: typeof LOAD_SOURCE_DELIVERY_FACTOR_DATA;
  payload: SourceDeliveryFactor[];
}

export interface SetSourceDeliveryFactorCurrentCompanyAction {
  type: typeof SET_SOURCE_DELIVERY_FACTOR_CURRENT_COMPANY;
  payload: { companyId: string };
}

export interface AddSourceDeliveryFactorAction {
  type: typeof ADD_SOURCE_DELIVERY_FACTOR;
  payload: {
    newSourceDeliveryFactor: SourceDeliveryFactor;
  };
}

export interface EditSourceDeliveryFactorAction {
  type: typeof EDIT_SOURCE_DELIVERY_FACTOR;
  payload: {
    id: string;
    updatedSourceDeliveryFactor: SourceDeliveryFactor;
  };
}

export interface DeleteSourceDeliveryFactorAction {
  type: typeof DELETE_SOURCE_DELIVERY_FACTOR;
  payload: { id: string };
}

export interface UpdateSourceDeliveryFactorStatusAction {
  type: typeof UPDATE_SOURCE_DELIVERY_FACTOR_STATUS;
  payload: { status: SourceDeliveryFactorStatus };
}

export interface SetSourceDeliveryFactorErrorAction {
  type: typeof SET_SOURCE_DELIVERY_FACTOR_ERROR;
  payload: { error?: SourceDeliveryFactorError };
}

export interface ResetSourceDeliveryFactorAction {
  type: typeof RESET_SOURCE_DELIVERY_FACTOR_STATE;
}

export type SourceDeliveryFactorAction =
  | LoadSourceDeliveryFactorDataAction
  | AddSourceDeliveryFactorAction
  | EditSourceDeliveryFactorAction
  | DeleteSourceDeliveryFactorAction
  | UpdateSourceDeliveryFactorStatusAction
  | SetSourceDeliveryFactorErrorAction
  | SetSourceDeliveryFactorCurrentCompanyAction
  | ResetSourceDeliveryFactorAction;
