import { AppThunk } from "../..";
import { executeApiCall } from "../../../util/utils";
import { openAlert } from "../../alert/actions";
import { clearDialog } from "../../dialog/actions";
import {
  COGS,
  COGSAction,
  COGSError,
  COGSInput,
  COGSStatus,
  DELETE_COGS,
  LOAD_COGS_DATA,
  RESET_COGS_STATE,
  SET_COGS_CURRENT_COMPANY,
  SET_COGS_ERROR,
  UPDATE_COGS_STATUS,
} from "./types";

const url = "price-adjustment/cogs";
const successResponses = [200, 201, 204];

// SYNC Action Creators
const updateCOGSStatus = (status: COGSStatus): COGSAction => ({
  type: UPDATE_COGS_STATUS,
  payload: { status },
});

export const loadCOGSData = (data: COGS[]): COGSAction => ({
  type: LOAD_COGS_DATA,
  payload: data,
});

const setCOGSError = (error: COGSError): COGSAction => ({
  type: SET_COGS_ERROR,
  payload: { error },
});

export const setCOGSCurrentCompany = (companyId: string): COGSAction => ({
  type: SET_COGS_CURRENT_COMPANY,
  payload: { companyId },
});

export const resetCOGSState = (): COGSAction => ({
  type: RESET_COGS_STATE,
});

// ASYNC Actions (Thunk Actions)
export const fetchCOGSList =
  (companyId?: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(updateCOGSStatus("loading"));
      const system = getState().system;
      const token = system.session.token;
      const _companyId = companyId ? companyId : system.session.userDetails.companyId;

      const response = await executeApiCall(token).get(`${url}?companyId=${_companyId}`);
      dispatch(loadCOGSData(response.data));
    } catch (err: any) {
      handleError(err, dispatch);
    } finally {
      dispatch(updateCOGSStatus("idle"));
    }
  };

export const createCOGS =
  (currentCompanyId: string, cogs: COGSInput, successCb?: () => void): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(updateCOGSStatus("loading"));

      const system = getState().system;
      // const { currentCompanyId } = getState().branchState;
      const token = system.session.token;
      const companyId = currentCompanyId ? currentCompanyId : system.session.userDetails.companyId;

      console.log(companyId);

      const data = { ...cogs, companyId };
      const response = await executeApiCall(token).post(url, data);

      if (successResponses.includes(response.status)) {
        dispatch(clearDialog());
        dispatch(updateCOGSStatus("success"));
        dispatch(openAlert("Successfully saved!", "success"));

        if (successCb) successCb();
      }
    } catch (err: any) {
      handleError(err, dispatch);
    } finally {
      dispatch(updateCOGSStatus("idle"));
    }
  };

export const editCOGS =
  (currentCompanyId: string, id: string, cogs: COGSInput, successCb?: () => void): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(updateCOGSStatus("loading"));
      const system = getState().system;
      const token = system.session.token;
      const companyId = currentCompanyId ? currentCompanyId : system.session.userDetails.companyId;

      const data = { ...cogs, companyId };
      const response = await executeApiCall(token).put(`${url}/${id}`, data);

      if (successResponses.includes(response.status)) {
        dispatch(clearDialog());
        dispatch(updateCOGSStatus("success"));
        dispatch(openAlert("Successfully saved!", "success"));

        if (successCb) successCb();
      }
    } catch (err: any) {
      handleError(err, dispatch);
    } finally {
      dispatch(updateCOGSStatus("idle"));
    }
  };

export const deleteCOGS =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(updateCOGSStatus("loading"));

      const system = getState().system;
      const token = system.session.token;

      await executeApiCall(token).delete(`${url}/${id}`);

      // show success message
      dispatch(clearDialog());
      dispatch(openAlert("Successfully deleted!", "success"));

      dispatch({ type: DELETE_COGS, payload: { id } });
    } catch (err: any) {
      handleError(err, dispatch);
    } finally {
      dispatch(updateCOGSStatus("idle"));
    }
  };

const handleError = (err: any, dispatch: any) => {
  let message = "Error";

  if (err?.response?.data) {
    const error = err.response.data.error;
    message = error.message ? error.message.split(": ")[1] : "Error";
  }

  dispatch(updateCOGSStatus("failed"));
  dispatch(setCOGSError(message));

  // show error message
  dispatch(openAlert(message, "error"));

  dispatch(clearDialog());
};
