import Box from "@material-ui/core/Box";
import { TableColumn, appColors } from "../../../util/constants";
import { formatDateForTable, useDialog } from "../../../util/utils";
import { RepnotesActionButton } from "../../common/RepnotesButton";
import Chip from "@material-ui/core/Chip";
import { useListPermission } from "./hooks";
import { SourceFactory } from "../../../store/listManagement/sourceFactory/types";

const SourceFactoryActionButton = ({
  sourceFactory,
}: {
  sourceFactory: SourceFactory;
}) => {
  const { canEdit, canDelete } = useListPermission();
  const { openDeleteDialog } = useDialog();

  return (
    <Box display="flex" flexDirection="row" height={35}>
      {canEdit && (
        <RepnotesActionButton
          link="source-factory"
          docId={sourceFactory.id}
          type="edit"
        />
      )}
      {canDelete && (
        <RepnotesActionButton
          onClick={() => openDeleteDialog(sourceFactory.name, sourceFactory.id)}
          type="delete"
        />
      )}
    </Box>
  );
};

const SourceFactorColumns: TableColumn[] = [
  {
    id: "name",
    label: "Name",
    property: "name",
    sortable: true,
    align: "left",
  },
  {
    id: "isActive",
    label: "Status",
    key: "sourceFactory-isActive",
    sortable: true,
    content: (sourceFactory: SourceFactory) => (
      <Chip
        size="small"
        label={!sourceFactory.isActive ? "Inactive" : "Active"}
        style={{
          backgroundColor: sourceFactory.isActive
            ? appColors.active
            : appColors.inactive,
          color: appColors.white,
        }}
      />
    ),
  },
  {
    id: "dateCreated",
    label: "Date Created",
    property: "dateCreated",
    sortable: true,
    content: (sourceFactory: SourceFactory) =>
      formatDateForTable(sourceFactory.dateCreated),
  },
  {
    id: "dateUpdated",
    label: "Date Updated",
    property: "dateUpdated",
    sortable: true,
    content: (sourceFactory: SourceFactory) =>
      formatDateForTable(sourceFactory.dateUpdated),
  },
  {
    id: "action",
    label: "Actions",
    key: "sourceFactory-actions",
    sortable: false,
    content: (sourceFactory: SourceFactory) => (
      <SourceFactoryActionButton sourceFactory={sourceFactory} />
    ),
  },
];

export default SourceFactorColumns;
