import { initialMarketResult, MarketReportState } from "./types";

const initialState: MarketReportState = {
  // ui
  loading: false,
  uploadLoading: false,
  activeTab: "REPORT",
  dialogOpen: false,

  // data
  report: [],
  uploadedMarketList: [],
  rollingMarketSize: initialMarketResult,
  rollingShare: initialMarketResult,
  rollingUnitSales: initialMarketResult,
  rollingYear: "",
  marketSize: 0,
  unitSales: 0,

  // filter options
  productFamilyOptions: [],
  salesPersonOptions: [],

  // selected filters
  selectedCompany: "",
  selectedProductFamily: "",
  selectedSalesperson: "",
};

const marketReportReducer = (state = initialState, action: any): MarketReportState => {
  return state;
};

export default marketReportReducer;
