import Box from "@material-ui/core/Box";
import AppTable, { useTableUtils } from "../../common/RepnotesAMITable";
import { useDispatch, useSelector } from "react-redux";

import { LoadingDialog, RepnotesDialog } from "../../common/RepnotesAlerts";
import { useDialog } from "../../../util/utils";
import SourceFactoryColumns from "./SourceFactoryColumns";
import SourceFactoryControlBar from "./SourceFactoryControlBar";
import {
  selectSourceFactoryState,
  selectTransformedSourceFactoryData,
} from "../../../store/listManagement/sourceFactory/selectors";
import { useFetchSourceFactory } from "./hooks";
import { deleteSourceFactory } from "../../../store/listManagement/sourceFactory/actions";

const SourceFactoryList = () => {
  const {
    sort,
    paginate,
    sortColumn,
    sortHandler,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    search,
    setSearch,
    filterBySearch,
  } = useTableUtils({ initialRowsPerPage: 10 });

  const dispatch = useDispatch();
  const { status } = useSelector(selectSourceFactoryState);
  const data = useSelector(selectTransformedSourceFactoryData);
  const loading = status === "loading";
  //   const { canEdit, canDelete } = useListPermission();
  const { dialog, closeDialog } = useDialog();

  useFetchSourceFactory();

  const filteredData = search ? filterBySearch(data) : data;

  const sortedData = sort(filteredData, sortColumn.path, sortColumn.order);

  const paginatedData = paginate(sortedData, page, rowsPerPage);

  const columns = SourceFactoryColumns;

  const handleDelete = () => {
    dispatch(deleteSourceFactory(dialog.docId as string));
  };

  return (
    <>
      <Box className="repnotes-content">
        <SourceFactoryControlBar search={search} onSearch={setSearch} />
        {loading ? (
          <LoadingDialog />
        ) : (
          <AppTable style={{ width: "100%" }}>
            <AppTable.Container stickyHeader={false}>
              <AppTable.Head
                sortColumn={sortColumn}
                onSort={sortHandler}
                columns={columns}
              />
              <AppTable.Body columns={columns} data={paginatedData} />
            </AppTable.Container>
            <AppTable.Pagination
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              component="div"
              count={sortedData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </AppTable>
        )}
      </Box>
      <RepnotesDialog
        label={dialog.dialogLabel}
        open={dialog.dialogOpen}
        severity={dialog.dialogType}
        onClick={handleDelete}
        onClear={closeDialog}
      />
    </>
  );
};
export default SourceFactoryList;
