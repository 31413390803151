import { Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  resetCustomerImport,
  saveImportCustomerList,
  selectCustomerState,
} from "../../../store/customerManagement/customer/actions";
import { TableColumn } from "../../../util/constants";
import ImportDialog from "../../common/ImportDialog";
import AppTable, { useTableUtils } from "../../common/RepnotesAMITable";

const importedCustomerColumns: TableColumn[] = [
  {
    id: "name",
    label: "Customer Name",
    property: "name",
    sortable: true,
    removePadding: true,
  },
  {
    id: "customerType",
    label: "Type",
    property: "customerType",
    sortable: true,
  },
  {
    id: "salesPerson",
    label: "Salesperson",
    property: "salesPerson",
    sortable: true,
  },
  {
    id: "contactNo1",
    label: "Contact Number",
    property: "contactNo1",
    sortable: true,
    removePadding: true,
  },
  {
    id: "email",
    label: "Email",
    property: "email",
    sortable: true,
  },
];

const CustomerImportView = () => {
  const {
    importLoading,
    importCustomerList: data,
    dialogOpen,
    summaryDialog,
    uploadingText,
  } = useSelector(selectCustomerState);
  const dispath = useDispatch();

  const {
    sort,
    paginate,
    sortColumn,
    sortHandler,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    resetToPageOne,
  } = useTableUtils({ initialRowsPerPage: 5 });

  const columns = summaryDialog
    ? [
        ...importedCustomerColumns,
        {
          id: "remarks",
          label: "Remarks",
          property: "remarks",
          sortable: true,
          content: (item: any) => (
            <Typography
              style={{
                fontSize: "12px",
                color: item.remarks?.includes("Invalid") ? "#c00200" : "#70ad47",
              }}
            >
              {item.remarks}
            </Typography>
          ),
        },
      ]
    : importedCustomerColumns;

  const sortedData = sort(data, sortColumn.path, sortColumn.order);

  const paginatedData = paginate(sortedData, page, rowsPerPage);

  const handleClose = () => {
    dispath(resetCustomerImport());
    resetToPageOne();
  };

  return (
    <ImportDialog
      progressText={`Uploading ${uploadingText} ...`}
      isImporting={importLoading}
      onSave={() => dispath(saveImportCustomerList())}
      onClose={handleClose}
      open={dialogOpen}
      dialogFor='customer import'
      isSummary={summaryDialog}
    >
      <AppTable style={{ maxWidth: "100%" }}>
        <AppTable.Container stickyHeader={false}>
          <AppTable.Head sortColumn={sortColumn} onSort={sortHandler} columns={columns} />
          <AppTable.Body columns={columns} data={paginatedData} />
        </AppTable.Container>
        <AppTable.Pagination
          rowsPerPageOptions={[5, 10, 50]}
          component='div'
          count={sortedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </AppTable>
    </ImportDialog>
  );
};

export default CustomerImportView;
