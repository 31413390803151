// State Interfaces
export interface COGSBase {
  brand: string;
  make: string;
  packaging: string;
  priceAdjustment: number;
  priceAdjustmentType: "amount" | "percentage";
  sourceFactory: string;
  type: string;
  isActive: boolean;
}
export interface COGS extends COGSBase {
  id: string;
  companyId: string;
  dateCreated: string;
  dateUpdated: string;
}

export type COGSStatus = "idle" | "success" | "loading" | "failed";
export type COGSError = string | null;
export type COGSInput = COGSBase;

export const initialCOGsInputValue: COGSInput = {
  brand: "",
  make: "",
  packaging: "",
  priceAdjustment: 0,
  priceAdjustmentType: "amount",
  sourceFactory: "",
  type: "",
  isActive: true,
};

export interface COGSState {
  status: COGSStatus;
  data: COGS[];
  error?: COGSError;
  currentCompanyId?: string;
}

// Action Types
export const LOAD_COGS_DATA = "cogs/fetch";
export const ADD_COGS = "cogs/add";
export const EDIT_COGS = "cogs/edit";
export const DELETE_COGS = "cogs/delete";
export const UPDATE_COGS_STATUS = "cogs/status";
export const SET_COGS_ERROR = "cogs/error";
export const SET_COGS_CURRENT_COMPANY = "cogs/company";
export const RESET_COGS_STATE = "cogs/reset";

export interface LoadCOGSDataAction {
  type: typeof LOAD_COGS_DATA;
  payload: COGS[];
}

export interface SetCOGSCurrentCompanyAction {
  type: typeof SET_COGS_CURRENT_COMPANY;
  payload: { companyId: string };
}

export interface AddCOGSAction {
  type: typeof ADD_COGS;
  payload: {
    newCOGS: COGS;
  };
}

export interface EditCOGSAction {
  type: typeof EDIT_COGS;
  payload: {
    id: string;
    updatedCOGS: COGS;
  };
}

export interface DeleteCOGSAction {
  type: typeof DELETE_COGS;
  payload: { id: string };
}

export interface UpdateCOGSStatusAction {
  type: typeof UPDATE_COGS_STATUS;
  payload: { status: COGSStatus };
}

export interface SetCOGSErrorAction {
  type: typeof SET_COGS_ERROR;
  payload: { error?: COGSError };
}

export interface ResetCOGSAction {
  type: typeof RESET_COGS_STATE;
}

export type COGSAction =
  | LoadCOGSDataAction
  | AddCOGSAction
  | EditCOGSAction
  | DeleteCOGSAction
  | UpdateCOGSStatusAction
  | SetCOGSErrorAction
  | SetCOGSCurrentCompanyAction
  | ResetCOGSAction;
