import { mapToOptions } from "../../../util/utils";
import { AppState } from "../../index";

export const selectCOGSState = (state: AppState) => state.cogsState;

export const selectCOGSById = (id: string) => (state: AppState) =>
  state.cogsState.data.find((b) => b.id === id);

export const selectTransformedCOGSData = (state: AppState) =>
  state.cogsState.data.map((item) => ({
    ...item,
    make: state.makeState.makeList.find((m) => m.id === item.make)?.name,
    sourceFactory: state.sourceFactoryState.data.find(
      (s) => s.id === item.sourceFactory
    )?.name,
    productCode: [
      state.makeState.makeList.find((m) => m.id === item.make)?.name,
      item.brand,
      item.packaging,
    ].join("_"),
  }));

export const selectCOGSByProductCode =
  (productCode: string) => (state: AppState) => {
    const cogsData = selectTransformedCOGSData(state);
    return cogsData.find((cogs) => cogs.productCode === productCode);
  };

export const selectCOGSByMake = (makeId: string) => (state: AppState) =>
  state.cogsState.data.find((c) => c.make === makeId);

export const selectCOGSProductCodeOptions = (state: AppState) => {
  const cogsProductCodes = state.cogsState.data
    .map((item) =>
      [
        state.makeState.makeList.find((m) => m.id === item.make)?.name,
        item.brand,
        item.packaging,
      ].join("_")
    )
    .sort();

  return mapToOptions(cogsProductCodes).options;
};
