import { AppThunk } from "../..";
import { executeApiCall } from "../../../util/utils";
import { openAlert } from "../../alert/actions";
import { clearDialog } from "../../dialog/actions";
import {
  SourceFactory,
  SourceFactoryAction,
  SourceFactoryError,
  SourceFactoryStatus,
  LOAD_SOURCEFACTORY_DATA,
  RESET_SOURCEFACTORY_STATE,
  SET_SOURCEFACTORY_CURRENT_COMPANY,
  SET_SOURCEFACTORY_ERROR,
  UPDATE_SOURCEFACTORY_STATUS,
  DELETE_SOURCEFACTORY,
  SourceFactoryInput,
} from "./types";

const url = "list/source-factory";
const successResponses = [200, 201, 204];

// SYNC Action Creators
const updateSourceFactoryStatus = (
  status: SourceFactoryStatus
): SourceFactoryAction => ({
  type: UPDATE_SOURCEFACTORY_STATUS,
  payload: { status },
});

export const loadSourceFactoryData = (
  data: SourceFactory[]
): SourceFactoryAction => ({
  type: LOAD_SOURCEFACTORY_DATA,
  payload: data,
});

const setSourceFactoryError = (
  error: SourceFactoryError
): SourceFactoryAction => ({
  type: SET_SOURCEFACTORY_ERROR,
  payload: { error },
});

export const setSourceFactoryCurrentCompany = (
  companyId: string
): SourceFactoryAction => ({
  type: SET_SOURCEFACTORY_CURRENT_COMPANY,
  payload: { companyId },
});

export const resetSourceFactoryState = (): SourceFactoryAction => ({
  type: RESET_SOURCEFACTORY_STATE,
});

// ASYNC Actions (Thunk Actions)

export const fetchSourceFactoryList =
  (companyId?: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(updateSourceFactoryStatus("loading"));
      const system = getState().system;
      const token = system.session.token;
      const _companyId = companyId
        ? companyId
        : system.session.userDetails.companyId;

      const response = await executeApiCall(token).get(
        `${url}?companyId=${_companyId}`
      );

      dispatch(loadSourceFactoryData(response.data));
    } catch (err: any) {
      handleError(err, dispatch);
    } finally {
      dispatch(updateSourceFactoryStatus("idle"));
    }
  };

export const createSourceFactory =
  (
    formData: SourceFactoryInput, 
    successCb?: () => void
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(updateSourceFactoryStatus("loading"));

      const system = getState().system;
      const { currentCompanyId } = getState().sourceFactoryState;
      const token = system.session.token;
      const companyId = currentCompanyId ? currentCompanyId : system.session.userDetails.companyId;

      const data = { ...formData, companyId };
      const response = await executeApiCall(token).post(url, data);

      if (successResponses.includes(response.status)) {
        dispatch(clearDialog());
        dispatch(updateSourceFactoryStatus("success"));
        dispatch(openAlert("Successfully saved!", "success"));

        if (successCb) successCb();
      }
    } catch (err: any) {
      handleError(err, dispatch);
    } finally {
      dispatch(updateSourceFactoryStatus("idle"));
    }
  };

export const editSourceFactory =
  (id: string, formData: SourceFactoryInput, successCb?: () => void): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(updateSourceFactoryStatus("loading"));
      const system = getState().system;
      const { currentCompanyId } = getState().sourceFactoryState;
      const token = system.session.token;
      const companyId = currentCompanyId ? currentCompanyId : system.session.userDetails.companyId;

      const data = { ...formData, companyId };
      const response = await executeApiCall(token).put(`${url}/${id}`, data);

      if (successResponses.includes(response.status)) {
        dispatch(clearDialog());
        dispatch(updateSourceFactoryStatus("success"));
        dispatch(openAlert("Successfully saved!", "success"));

        if (successCb) successCb();
      }
    } catch (err: any) {
      handleError(err, dispatch);
    } finally {
      dispatch(updateSourceFactoryStatus("idle"));
    }
  };

export const deleteSourceFactory =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(updateSourceFactoryStatus("loading"));

      const system = getState().system;
      const token = system.session.token;

      await executeApiCall(token).delete(`${url}/${id}`);

      // show success message
      dispatch(clearDialog());
      dispatch(openAlert("Successfully deleted!", "success"));

      dispatch({ type: DELETE_SOURCEFACTORY, payload: { id } });
    } catch (err: any) {
      handleError(err, dispatch);
    } finally {
      dispatch(updateSourceFactoryStatus("idle"));
    }
  };

const handleError = (err: any, dispatch: any) => {
  let message = "Error";

  if (err?.response?.data) {
    const error = err.response.data.error;
    message = error.message ? error.message.split(": ")[1] : "Error";
  }

  dispatch(updateSourceFactoryStatus("failed"));
  dispatch(setSourceFactoryError(message));

  // show error message
  dispatch(openAlert(message, "error"));

  dispatch(clearDialog());
};
