import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMakeList } from "../../../store/listManagement/make/actions";
import { fetchSourceFactoryList } from "../../../store/listManagement/sourceFactory/actions";

import { fetchSourceDeliveryFactorList } from "../../../store/priceAdjustmentFactors/sourceDeliveryFactor/actions";
import { selectSourceDeliveryFactorState } from "../../../store/priceAdjustmentFactors/sourceDeliveryFactor/selectors";
import { selectSystemState } from "../../../store/system/actions";

export function useListPermission() {
  const { session } = useSelector(selectSystemState);
  const { modules } = session;
  // TODO: Change to Price Adj Permissions
  const canEdit = modules.listManagement.edit;
  const canDelete = modules.listManagement.delete;
  const canAdd = modules.listManagement.add;
  const canView = modules.listManagement.view;

  return { canEdit, canDelete, canAdd, canView };
}

export function useFetchSourceDeliveryFactor() {
  const dispatch = useDispatch();
  const system = useSelector(selectSystemState);
  const { status, error, data, currentCompanyId } = useSelector(
    selectSourceDeliveryFactorState
  );


  useEffect(() => {
    if (!currentCompanyId) return;
    
    dispatch(getMakeList(system, currentCompanyId));
  }, [currentCompanyId, dispatch, system]);

  useEffect(() => {
    if (!currentCompanyId) return;

    dispatch(fetchSourceFactoryList(currentCompanyId));
  }, [currentCompanyId, dispatch]);

  useEffect(() => {
    if (!currentCompanyId) return;

    dispatch(fetchSourceDeliveryFactorList(currentCompanyId));
  }, [currentCompanyId, dispatch]);

  return { loading: status === "loading", error, data };
}
