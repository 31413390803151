import { AppThunk } from "../..";
import { executeApiCall } from "../../../util/utils";
import { openAlert } from "../../alert/actions";
import { clearDialog } from "../../dialog/actions";
import {
  SourceDeliveryFactor,
  SourceDeliveryFactorAction,
  SourceDeliveryFactorError,
  SourceDeliveryFactorInput,
  SourceDeliveryFactorStatus,
  DELETE_SOURCE_DELIVERY_FACTOR,
  LOAD_SOURCE_DELIVERY_FACTOR_DATA,
  RESET_SOURCE_DELIVERY_FACTOR_STATE,
  SET_SOURCE_DELIVERY_FACTOR_CURRENT_COMPANY,
  SET_SOURCE_DELIVERY_FACTOR_ERROR,
  UPDATE_SOURCE_DELIVERY_FACTOR_STATUS,
} from "./types";

const url = "price-adjustment/source-delivery-factor";
const successResponses = [200, 201, 204];

// SYNC Action Creators
const updateSourceDeliveryFactorStatus = (
  status: SourceDeliveryFactorStatus
): SourceDeliveryFactorAction => ({
  type: UPDATE_SOURCE_DELIVERY_FACTOR_STATUS,
  payload: { status },
});

export const loadSourceDeliveryFactorData = (
  data: SourceDeliveryFactor[]
): SourceDeliveryFactorAction => ({
  type: LOAD_SOURCE_DELIVERY_FACTOR_DATA,
  payload: data,
});

const setSourceDeliveryFactorError = (
  error: SourceDeliveryFactorError
): SourceDeliveryFactorAction => ({
  type: SET_SOURCE_DELIVERY_FACTOR_ERROR,
  payload: { error },
});

export const setSourceDeliveryFactorCurrentCompany = (
  companyId: string
): SourceDeliveryFactorAction => ({
  type: SET_SOURCE_DELIVERY_FACTOR_CURRENT_COMPANY,
  payload: { companyId },
});

export const resetSourceDeliveryFactorState = (): SourceDeliveryFactorAction => ({
  type: RESET_SOURCE_DELIVERY_FACTOR_STATE,
});

// ASYNC Actions (Thunk Actions)
export const fetchSourceDeliveryFactorList =
  (companyId?: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(updateSourceDeliveryFactorStatus("loading"));
      const system = getState().system;
      const token = system.session.token;
      const _companyId = companyId ? companyId : system.session.userDetails.companyId;

      const response = await executeApiCall(token).get(`${url}?companyId=${_companyId}`);
      dispatch(loadSourceDeliveryFactorData(response.data));
    } catch (err: any) {
      handleError(err, dispatch);
    } finally {
      dispatch(updateSourceDeliveryFactorStatus("idle"));
    }
  };

export const createSourceDeliveryFactor =
  (
    currentCompanyId: string,
    formData: SourceDeliveryFactorInput,
    successCb?: () => void
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(updateSourceDeliveryFactorStatus("loading"));

      const system = getState().system;
      const token = system.session.token;
      const companyId = currentCompanyId ? currentCompanyId : system.session.userDetails.companyId;

      const data = { ...formData, companyId };
      const response = await executeApiCall(token).post(url, data);

      if (successResponses.includes(response.status)) {
        dispatch(clearDialog());
        dispatch(updateSourceDeliveryFactorStatus("success"));
        dispatch(openAlert("Successfully saved!", "success"));

        if (successCb) successCb();
      }
    } catch (err: any) {
      handleError(err, dispatch);
    } finally {
      dispatch(updateSourceDeliveryFactorStatus("idle"));
    }
  };

export const editSourceDeliveryFactor =
  (
    currentCompanyId: string,
    id: string,
    formData: SourceDeliveryFactorInput,
    successCb?: () => void
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(updateSourceDeliveryFactorStatus("loading"));
      const system = getState().system;
      const token = system.session.token;
      const companyId = currentCompanyId ? currentCompanyId : system.session.userDetails.companyId;

      const data = { ...formData, companyId };
      const response = await executeApiCall(token).put(`${url}/${id}`, data);

      if (successResponses.includes(response.status)) {
        dispatch(clearDialog());
        dispatch(updateSourceDeliveryFactorStatus("success"));
        dispatch(openAlert("Successfully saved!", "success"));

        if (successCb) successCb();
      }
    } catch (err: any) {
      handleError(err, dispatch);
    } finally {
      dispatch(updateSourceDeliveryFactorStatus("idle"));
    }
  };

export const deleteSourceDeliveryFactor =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(updateSourceDeliveryFactorStatus("loading"));

      const system = getState().system;
      const token = system.session.token;

      await executeApiCall(token).delete(`${url}/${id}`);

      // show success message
      dispatch(clearDialog());
      dispatch(openAlert("Successfully deleted!", "success"));

      dispatch({ type: DELETE_SOURCE_DELIVERY_FACTOR, payload: { id } });
    } catch (err: any) {
      handleError(err, dispatch);
    } finally {
      dispatch(updateSourceDeliveryFactorStatus("idle"));
    }
  };

const handleError = (err: any, dispatch: any) => {
  let message = "Error";

  if (err?.response?.data) {
    const error = err.response.data.error;
    message = error.message ? error.message.split(": ")[1] : "Error";
  }

  dispatch(updateSourceDeliveryFactorStatus("failed"));
  dispatch(setSourceDeliveryFactorError(message));

  // show error message
  dispatch(openAlert(message, "error"));

  dispatch(clearDialog());
};
