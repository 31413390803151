import {
  initialMarginResult,
  MarginReportAction,
  MarginReportState,
  SET_MARGIN_REPORT_COMPANY,
  SET_MARGIN_REPORT_FILTER_OPTION,
  SET_MARGIN_REPORT_IMPORTED_DATA,
  SET_MARGIN_REPORT_LOADING,
  SET_MARGIN_REPORT_REPORT_DATA,
  SET_MARGIN_REPORT_SELECTED_FILTER,
  SET_MARGIN_REPORT_STATUS_DATA,
  SET_MARGIN_REPORT_TAB,
  SET_MARGIN_REPORT_UPLOADING,
  TOGGLE_MARGIN_REPORT_IMPORT_DIALOG,
} from "./types";

const initialState: MarginReportState = {
  // ui
  loading: false,
  uploadLoading: false,
  activeTab: "REPORT",
  dialogOpen: false,

  // data
  report: [],
  uploadedMarginList: [],
  marginResult: initialMarginResult,
  rollingYear: "",
  invoiceAmount: 0,
  cost: 0,

  // filter options
  productFamilyOptions: [],
  modelOptions: [],
  customerTypeOptions: [],
  customerOptions: [],
  provinceOptions: [],
  salesPersonOptions: [],

  // selected filters
  selectedCompany: "",
  selectedCustomerType: "",
  selectedProvince: "",
  selectedModel: "",
  selectedProductFamily: "",
  selectedSalesperson: "",
  selectedMake: "",
  selectedCustomer: "",
  marginStartDate: "",
  marginEndDate: "",
};

const marginReportReducer = (
  state = initialState,
  action: MarginReportAction
): MarginReportState => {
  switch (action.type) {
    case SET_MARGIN_REPORT_COMPANY:
      return { ...state, selectedCompany: action.payload.selectedCompany };
    case SET_MARGIN_REPORT_TAB:
      return { ...state, activeTab: action.payload.tab };
    case SET_MARGIN_REPORT_LOADING:
      return { ...state, loading: action.payload.loading };
    case SET_MARGIN_REPORT_REPORT_DATA:
      return { ...state, report: action.payload.report };
    case SET_MARGIN_REPORT_STATUS_DATA:
      return { ...state, ...action.payload };
    case SET_MARGIN_REPORT_FILTER_OPTION:
      return { ...state, [action.payload.key]: action.payload.optionData };
    case SET_MARGIN_REPORT_SELECTED_FILTER:
      return { ...state, [action.payload.key]: action.payload.filterValue };
    case SET_MARGIN_REPORT_IMPORTED_DATA:
      return { ...state, uploadedMarginList: action.payload.uploadedMarginList };
    case TOGGLE_MARGIN_REPORT_IMPORT_DIALOG:
      return { ...state, dialogOpen: action.payload.dialogOpen };
    case SET_MARGIN_REPORT_UPLOADING:
      return { ...state, uploadLoading: action.payload.uploading };
    default:
      return state;
  }
};

export default marginReportReducer;
