import { useState, ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import { RepnotesInput } from "../../common/RepnotesInput";
import {
  selectCOGSById,
  selectCOGSState,
} from "../../../store/priceAdjustmentFactors/cogs/selectors";
import { COGSInput, initialCOGsInputValue } from "../../../store/priceAdjustmentFactors/cogs/types";
import { selectMakeList } from "../../../store/listManagement/make/actions";
import { mapToOptions, useDialog } from "../../../util/utils";
import PriceAdjustmentField from "../../common/PriceAdjustmentField";
import { FormRadioGroup } from "../../fieldsManagement/shared/FormLib";
import { priceAdjustmentTypeOptions } from "../../../util/constants";
import InputContainerForForm from "../../common/InputContainerForForm";
import { appColors } from "../../fieldsManagement/shared/constants";
import { RepnotesDialog } from "../../common/RepnotesAlerts";
import { openAlert } from "../../../store/alert/actions";
import { createCOGS, editCOGS } from "../../../store/priceAdjustmentFactors/cogs/actions";
import RepnotesFormShell from "../../common/RepnotesFormShell";
import { selectSourceFactoryDataList } from "../../../store/listManagement/sourceFactory/selectors";

interface IParams {
  id: string;
}

const COGSForm = () => {
  const history = useHistory();
  const { status, currentCompanyId } = useSelector(selectCOGSState);
  const { id } = useParams<IParams>();
  const dispatch = useDispatch();

  const [COGS, setCOGS] = useState<COGSInput>(initialCOGsInputValue);
  const [errorObj, setErrorObj] = useState<Record<string, boolean>>({});
  const COGStoEdit = useSelector(selectCOGSById(id));

  const { dialog, closeDialog, openConfirmSaveDialog } = useDialog();

  const sourceFactoryList = useSelector(selectSourceFactoryDataList);
  const sourceFactoryOptions = mapToOptions(sourceFactoryList);

  const makeList = useSelector(selectMakeList);
  const makeOptions = mapToOptions(makeList);

  const isSaveBtnShown = true; // TODO: update with permission consideration
  const isLoading = status === "loading";
  const isAdding = id === "new";

  useEffect(() => {
    if (isAdding || !COGStoEdit) return;
    setCOGS({ ...COGStoEdit });
  }, [COGStoEdit, isAdding]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorObj({});
    const value = e.target.value;
    const key = e.target.id as keyof COGSInput;

    setCOGS((prev) => ({ ...prev, [key]: value }));
  };

  const handleSelectChange =
    (key: keyof COGSInput) => (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      setErrorObj({});
      const value = e.target.value;
      setCOGS((prev) => ({ ...prev, [key]: value }));
    };

  const handlePriceChange = (value: number) =>
    setCOGS((prev) => ({ ...prev, priceAdjustment: value }));

  const handlePriceTypeChange = (value: "amount" | "percentage") =>
    setCOGS((prev) => ({ ...prev, priceAdjustmentType: value }));

  const handleCancel = () => history.push("/cogs");

  const handleSave = () => {
    const errors = Object.keys(COGS).reduce<Record<string, boolean>>(
      (mapped, curr) => ({
        ...mapped,
        [curr]: COGS[curr as keyof typeof COGS] === "",
      }),
      {}
    );

    setErrorObj(errors);

    // MAKE VALIDATION
    // if (data.some((d) => d.make === COGS.make) && isAdding) {
    //   return dispatch(
    //     openAlert("The selected make already has a COGS adjustment.", "error")
    //   );
    // }

    // if (
    //   data.some((d) => d.make === COGS.make && d.make !== COGStoEdit?.make) &&
    //   !isAdding
    // ) {
    //   return dispatch(
    //     openAlert("The selected make already has a COGS adjustment.", "error")
    //   );
    // }

    if (Object.keys(errors).some((key) => errors[key as string] === true)) {
      return dispatch(openAlert("Please check required field.", "error"));
    }
    openConfirmSaveDialog("this COGS");
  };

  const handleDialogSaveClick = () => {
    const successCb = () => history.push("/cogs");
    if (!currentCompanyId) return;

    if (isAdding) dispatch(createCOGS(currentCompanyId, COGS, successCb));
    else dispatch(editCOGS(currentCompanyId, id, COGS, successCb));
  };

  return (
    <>
      <RepnotesFormShell
        moduleName='Price Adjustment Factors'
        subModuleName='COGS Adjustment'
        isSaveBtnShown={isSaveBtnShown}
        isLoading={isLoading}
        onCancel={handleCancel}
        onSave={handleSave}
      >
        <Grid item xs={7}>
          <RepnotesInput
            id='make'
            type='select'
            label='Make'
            labelPosition='left'
            value={COGS.make}
            options={makeOptions.options}
            disabled={false}
            error={errorObj["make"]}
            onSelectChange={handleSelectChange("make")}
          />
          <RepnotesInput
            id='packaging'
            type='text'
            placeholder='Packaging'
            label='Packaging'
            labelPosition='left'
            disabled={false}
            error={errorObj["packaging"]}
            value={COGS.packaging}
            onChange={handleChange}
          />
          <RepnotesInput
            id='type'
            type='text'
            placeholder='Type'
            label='Type'
            labelPosition='left'
            disabled={false}
            error={errorObj["type"]}
            value={COGS.type}
            onChange={handleChange}
          />
          <RepnotesInput
            id='brand'
            type='text'
            placeholder='Brand'
            label='Brand'
            labelPosition='left'
            disabled={false}
            error={errorObj["brand"]}
            value={COGS.brand}
            onChange={handleChange}
          />
          <RepnotesInput
            id='sourceFactory'
            type='select'
            placeholder='Source Factory'
            label='Source Factory'
            labelPosition='left'
            disabled={false}
            error={errorObj["sourceFactory"]}
            value={COGS.sourceFactory}
            options={sourceFactoryOptions.options}
            onSelectChange={handleSelectChange("sourceFactory")}
          />
          <InputContainerForForm label='Price Adjustment Type'>
            <FormRadioGroup
              options={priceAdjustmentTypeOptions}
              color={appColors.darkViolet}
              row
              id='priceAdjustmentType'
              onChange={(e, v) => handlePriceTypeChange(v as "amount" | "percentage")}
              value={COGS.priceAdjustmentType}
            />
          </InputContainerForForm>
          <PriceAdjustmentField
            id='priceAdjustment'
            label='Price Adjustment'
            value={COGS.priceAdjustment}
            onChange={handlePriceChange}
            priceType={COGS.priceAdjustmentType}
            error={errorObj["priceAdjustment"]}
          />
        </Grid>
        <RepnotesDialog
          label={dialog.dialogLabel}
          open={dialog.dialogOpen}
          severity={dialog.dialogType}
          onClick={handleDialogSaveClick}
          onClear={closeDialog}
        />
      </RepnotesFormShell>
    </>
  );
};

export default COGSForm;
