import { useState, ChangeEvent, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";

import RepnotesFormShell from "../../common/RepnotesFormShell";
import {
  mapToOptions,
  useDialog,
} from "../../../util/utils";
import { RepnotesInput } from "../../common/RepnotesInput";
import { RepnotesDialog } from "../../common/RepnotesAlerts";
import { openAlert } from "../../../store/alert/actions";
import { initialSFIBnputValue, SourceFactoryInput } from "../../../store/listManagement/sourceFactory/types";
import { selectSourceFactoryById, selectSourceFactoryState } from "../../../store/listManagement/sourceFactory/selectors";
import { createSourceFactory, editSourceFactory } from "../../../store/listManagement/sourceFactory/actions";

interface IParams {
  id: string;
}

const SourceFactoryForm = () => {
  const history = useHistory();
  const { status, currentCompanyId, data } = useSelector(
    selectSourceFactoryState
  );
  const { id } = useParams<IParams>();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState<SourceFactoryInput>(initialSFIBnputValue);
  const [errorObj, setErrorObj] = useState<Record<string, boolean>>({});
  const sdfToEdit = useSelector(selectSourceFactoryById(id));
  const { dialog, closeDialog, openConfirmSaveDialog } = useDialog();

  const isSaveBtnShown = true; // TODO: update with permission consideration
  const isLoading = status === "loading";
  const isAdding = id === "new";

  useEffect(() => {
    if (isAdding || !sdfToEdit) return;
    setFormData({ name: sdfToEdit ? sdfToEdit.name : '', isActive: sdfToEdit ? sdfToEdit.isActive : false });
  }, [sdfToEdit, isAdding]);

  const makeOptions = mapToOptions([
    { id: "true", name: "Active" },
    { id: "false", name: "Inactive" },
  ]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorObj({});
    const value = e.target.value;
    const key = e.target.id as keyof SourceFactoryInput;

    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleIsActiveChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setFormData({ ...formData, isActive: e.target.value === "true" ? true : false });
  };

  const handleCancel = () => history.push("/source-factory");

  const handleSave = () => {
    const errors = Object.keys(formData).reduce<Record<string, boolean>>(
      (mapped, curr) => ({
        ...mapped,
        [curr]: formData[curr as keyof typeof formData] === "",
      }),
      {}
    );

    setErrorObj(errors);

    if (data.some((d) => d.name === formData.name) && isAdding) {
      return dispatch(
        openAlert(
          "Source Factory Name is already exist.",
          "error"
        )
      );
    }

    if (Object.keys(errors).some((key) => errors[key as string] === true)) {
      return dispatch(openAlert("Please check required field.", "error"));
    }
    openConfirmSaveDialog("this Source Factory");
  };

  const handleDialogSaveClick = () => {
    const successCb = () => history.push("/source-factory");
    if (!currentCompanyId) return;

    if (isAdding)
      dispatch(
        createSourceFactory(formData, successCb)
      );
    else
      {
        dispatch(
          editSourceFactory(id, formData, successCb)
        );
    }
  };

  return (
    <>
      <RepnotesFormShell
        moduleName="List Management"
        subModuleName="Source Factory"
        isSaveBtnShown={isSaveBtnShown}
        isLoading={isLoading}
        onCancel={handleCancel}
        onSave={handleSave}
      >
        <Grid item xs={7}>
          <RepnotesInput
            id="name"
            type="text"
            placeholder="Name"
            label="Name"
            labelPosition="left"
            disabled={false}
            error={errorObj["name"]}
            value={formData.name}
            onChange={handleChange}
          />
          <RepnotesInput
            id="isActive"
            type="select"
            label="Status"
            labelPosition="left"
            value={formData.isActive}
            options={makeOptions.options}
            disabled={false}
            error={errorObj["isActive"]}
            onSelectChange={handleIsActiveChange}
          />
        </Grid>
      </RepnotesFormShell>
      <RepnotesDialog
        label={dialog.dialogLabel}
        open={dialog.dialogOpen}
        severity={dialog.dialogType}
        onClick={handleDialogSaveClick}
        onClear={closeDialog}
      />
    </>
  );
};

export default SourceFactoryForm;
