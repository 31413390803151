export type MarginReportTab = "REPORT" | "STATUS";
export type MarginReportFilterOptionsKeys =
  | "productFamilyOptions"
  | "modelOptions"
  | "customerTypeOptions"
  | "customerOptions"
  | "provinceOptions"
  | "salesPersonOptions";

export type MarginReportFilterKeys =
  | "selectedCompany"
  | "selectedCustomerType"
  | "selectedProvince"
  | "selectedModel"
  | "selectedProductFamily"
  | "selectedSalesperson"
  | "selectedCustomer"
  | "marginStartDate"
  | "marginEndDate";

export interface MarginItem {
  date: string;
  country: string;
  customer: string;
  customerType: string;
  province: string;
  model: string;
  productFamily: string;
  invoiceNo: string;
  invoiceQty: string | number;
  invoiceAmount: string | number;
  cost: string | number;
  margin: string | number;
  salesman: string;
}

export interface FilterOptionItem {
  value: string;
  label: string;
}

export interface MarginReportState {
  // ui
  loading: boolean;
  uploadLoading: boolean;
  activeTab: MarginReportTab;
  dialogOpen: boolean;

  // data
  report: MarginItem[];
  uploadedMarginList: MarginItem[];
  marginResult: MarginResult;
  rollingYear: string;
  invoiceAmount: number;
  cost: number;

  // filter options
  salesPersonOptions: FilterOptionItem[];
  productFamilyOptions: FilterOptionItem[];
  modelOptions: FilterOptionItem[];
  customerTypeOptions: FilterOptionItem[];
  customerOptions: FilterOptionItem[];
  provinceOptions: FilterOptionItem[];

  // selected filters
  selectedCompany: string;
  selectedCustomerType: string;
  selectedProvince: string;
  selectedModel: string;
  selectedProductFamily: string;
  selectedSalesperson: string;
  selectedMake: string;
  selectedCustomer: string;
  marginStartDate: string;
  marginEndDate: string;
}

export interface MarginResult {
  january: number;
  february: number;
  march: number;
  april: number;
  may: number;
  june: number;
  july: number;
  august: number;
  september: number;
  october: number;
  november: number;
  december: number;
}

export const initialMarginResult: MarginResult = {
  january: 0,
  february: 0,
  march: 0,
  april: 0,
  may: 0,
  june: 0,
  july: 0,
  august: 0,
  september: 0,
  october: 0,
  november: 0,
  december: 0,
};

// action types
export const SET_MARGIN_REPORT_COMPANY = "marginReport/filter/company/set";
export const SET_MARGIN_REPORT_TAB = "marginReport/tab/set";
export const SET_MARGIN_REPORT_LOADING = "marginReport/loading/set";
export const SET_MARGIN_REPORT_REPORT_DATA = "marginReport/report/set";
export const SET_MARGIN_REPORT_STATUS_DATA = "marginReport/status/set";
export const SET_MARGIN_REPORT_FILTER_OPTION = "marginReport/filterOptions/set";
export const SET_MARGIN_REPORT_SELECTED_FILTER = "marginReport/selectedFilter/set";
export const SET_MARGIN_REPORT_IMPORTED_DATA = "marginReport/importedData/set";
export const TOGGLE_MARGIN_REPORT_IMPORT_DIALOG = "marginReport/importDialog/toggle";
export const SET_MARGIN_REPORT_UPLOADING = "marginReport/uploading/set";

interface SetMarginReportCompanyAction {
  type: typeof SET_MARGIN_REPORT_COMPANY;
  payload: { selectedCompany: string };
}

interface SetMarginReportLoadingAction {
  type: typeof SET_MARGIN_REPORT_LOADING;
  payload: { loading: boolean };
}

interface SetMarginReportTabAction {
  type: typeof SET_MARGIN_REPORT_TAB;
  payload: { tab: MarginReportTab };
}

interface SetMarginReportReportDataAction {
  type: typeof SET_MARGIN_REPORT_REPORT_DATA;
  payload: { report: MarginItem[] };
}

interface SetMarginReportStatusDataAction {
  type: typeof SET_MARGIN_REPORT_STATUS_DATA;
  payload: { marginResult: MarginResult; rollingYear: string; invoiceAmount: number; cost: number };
}

interface SetMarginReportFilterOptionAction {
  type: typeof SET_MARGIN_REPORT_FILTER_OPTION;
  payload: {
    key: MarginReportFilterOptionsKeys;
    optionData: FilterOptionItem[];
  };
}

interface SetMarginReportSelectedFilterAction {
  type: typeof SET_MARGIN_REPORT_SELECTED_FILTER;
  payload: {
    key: MarginReportFilterKeys;
    filterValue: string;
  };
}

interface SetMarginReportImportedDataAction {
  type: typeof SET_MARGIN_REPORT_IMPORTED_DATA;
  payload: { uploadedMarginList: MarginItem[] };
}

interface ToggleMarginReportImportDialogAction {
  type: typeof TOGGLE_MARGIN_REPORT_IMPORT_DIALOG;
  payload: { dialogOpen: boolean };
}

interface SetMarginReportUploadingAction {
  type: typeof SET_MARGIN_REPORT_UPLOADING;
  payload: { uploading: boolean };
}

export type MarginReportAction =
  | SetMarginReportCompanyAction
  | SetMarginReportTabAction
  | SetMarginReportReportDataAction
  | SetMarginReportStatusDataAction
  | SetMarginReportLoadingAction
  | SetMarginReportFilterOptionAction
  | SetMarginReportSelectedFilterAction
  | SetMarginReportImportedDataAction
  | ToggleMarginReportImportDialogAction
  | SetMarginReportUploadingAction;
