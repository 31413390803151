import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

import { RepnotesContentHeader } from "../../common/RepnotesContentHeader";
import { RepnotesInput } from "../../common/RepnotesInput";
import { RepnotesAddButton } from "../../common/RepnotesButton";
import useCompany from "../../../util/utils";

import { superAdminCompanyValidation } from "../../../store/userManagement/user/actions";
import { setSourceFactoryCurrentCompany } from "../../../store/listManagement/sourceFactory/actions";
import { selectSourceFactoryState } from "../../../store/listManagement/sourceFactory/selectors";

interface Props {
  search: string;
  onSearch: React.Dispatch<React.SetStateAction<string>>;
}

const SourceFactoryControlBar = (props: Props) => {
  const { search, onSearch } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const { currentCompanyId } = useSelector(selectSourceFactoryState);

  const { isUserSuper, companySelectOptions } = useCompany();

  const handleCompanyChange = (
    e: React.ChangeEvent<HTMLInputElement | any>
  ) => {
    const companyName = e.target.value;
    dispatch(setSourceFactoryCurrentCompany(companyName));
  };

  const handleSearchClear = () => onSearch("");

  const handleAddClick = () => {
    if (!currentCompanyId) return dispatch(superAdminCompanyValidation());
    history.push("/source-factory/new");
  };

  return (
    <>
      <Box style={{ textAlign: "left", paddingTop: "10px 0px" }}>
        <RepnotesContentHeader
          moduleName="List Management"
          subModule="Source Factory"
        />
      </Box>
      <Grid container style={{ paddingTop: "20px" }}>
        {isUserSuper && (
          <Grid item xs={2}>
            <RepnotesInput
              id="repnotes-list-cogs"
              type="select"
              label="Company Name"
              labelPosition="top"
              options={companySelectOptions}
              value={currentCompanyId}
              onSelectChange={handleCompanyChange}
            />
          </Grid>
        )}
        <Box alignSelf="end" ml="auto" display="flex">
          <Box width="130px">
            <RepnotesInput
              id="search"
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onSearch(e.target.value);
              }}
              onClear={handleSearchClear}
            />
          </Box>
          <RepnotesAddButton onClick={handleAddClick}>
            <AddRoundedIcon />
          </RepnotesAddButton>
        </Box>
      </Grid>
    </>
  );
};

export default SourceFactoryControlBar;
