import { ChangeEvent, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { styled } from "@material-ui/styles";
import MinusIcon from "@material-ui/icons/RemoveOutlined";
import PlusIcon from "@material-ui/icons/AddOutlined";
import { RepnotesTextField } from "./RepnotesInput";
import { appColors } from "../dashboard/activityLog/utils/constants";

const PriceField = styled(RepnotesTextField)({
  ".MuiFormControl-root": {
    overflow: "hidden",
  },
  "& .MuiOutlinedInput-root": {
    overflow: "hidden",
    width: 140,
    borderRadius: 0,

    "& fieldset": {
      borderLeft: 0,
      borderRight: 0,
    },
  },

  "& .MuiOutlinedInput-input": {
    textAlign: "center",
  },
  "& .MuiOutlinedInput-adornedStart": {
    paddingLeft: 0,
  },
  "& .MuiOutlinedInput-adornedEnd": {
    paddingRight: 0,
  },
});

const PriceButton = styled(IconButton)({
  backgroundColor: appColors.darkViolet,
  color: "white",
  height: "34px",
  width: "36px",
  overflow: "hidden",
  borderRadius: 0,
  "&:hover": {
    backgroundColor: appColors.darkViolet,
  },
});

interface PriceAdjustmentFieldProps {
  id: string;
  label?: string;
  value?: number;
  onChange: (value: number) => void;
  priceType?: "amount" | "percentage";
  error?: boolean;
}

const PriceAdjustmentField = ({ value, ...rest }: PriceAdjustmentFieldProps) => {
  const { id, label, onChange, priceType, error } = rest;

  const [priceValue, setPriceValue] = useState(() => (value ? value.toString() : ""));
  
  useEffect(() => {
    if (value) {
      setPriceValue(value ? value.toString() : "");
    }
  }, [value]);

  useEffect(() => {
    if (priceValue !== "") return;

    setPriceValue(value ? value.toString() : "");
  }, [priceValue, value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isValidNumber = value.match(/^-?\d*\.?\d{0,6}$/);

    if (!isValidNumber) return;
    setPriceValue(value);

    onChange(parseFloat(value));
  };

  const increment = () => {
    const newValue = +priceValue + 1;
    setPriceValue(newValue.toString());
    onChange(newValue);
  };

  const decrement = () => {
    const newValue = +priceValue - 1;
    setPriceValue(newValue.toString());
    onChange(newValue);
  };

  return (
    <Box className='repnotes-input-container' position='relative'>
      <Grid container>
        <Grid
          item
          className='repnotes-input-label-container'
          xs={4}
          style={{
            textAlign: "right",
            paddingRight: "15px",
            paddingTop: "15px",
          }}
        >
          <Typography
            style={{
              padding: "3px 0",
              fontSize: 12,
              fontWeight: 700,
              color: "#272B75",
            }}
          >
            {label}
          </Typography>
        </Grid>
        <Grid item>
          <PriceField
            id={id}
            className='price-field'
            margin='dense'
            variant='outlined'
            type='text'
            value={priceValue?.toString()}
            onChange={handleChange}
            disabled={false}
            error={error}
            placeholder='0.00'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <PriceButton size='small' className='price-minus-btn' onClick={decrement}>
                    <MinusIcon color='inherit' />
                  </PriceButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <>
                    {priceType === "percentage" && (
                      <Typography
                        style={{
                          marginRight: "10px",
                          color: "#a4a4a4",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        %
                      </Typography>
                    )}
                    <PriceButton size='small' className='price-plus-btn' onClick={increment}>
                      <PlusIcon color='inherit' />
                    </PriceButton>
                  </>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PriceAdjustmentField;
