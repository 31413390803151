import { AppThunk } from "../..";
import { executeApiCall } from "../../../util/utils";
import { openAlert } from "../../alert/actions";
import { clearDialog } from "../../dialog/actions";
import {
  DELETE_PRICE_ADJ_TERMS,
  LOAD_PRICE_ADJ_TERMS_DATA,
  PriceAdjTermError,
  PriceAdjTermInput,
  PriceAdjTerms,
  PriceAdjTermsAction,
  PriceAdjTermStatus,
  RESET_PRICE_ADJ_TERMS_STATE,
  SET_PRICE_ADJ_TERMS_CURRENT_COMPANY,
  SET_PRICE_ADJ_TERMS_ERROR,
  UPDATE_PRICE_ADJ_TERMS_STATUS,
} from "./types";

const url = "price-adjustment/terms";
const successResponses = [200, 201, 204];

// SYNC Action Creators
const updatePriceAdjTermsStatus = (status: PriceAdjTermStatus): PriceAdjTermsAction => ({
  type: UPDATE_PRICE_ADJ_TERMS_STATUS,
  payload: { status },
});

export const loadPriceAdjTermsData = (data: PriceAdjTerms[]): PriceAdjTermsAction => ({
  type: LOAD_PRICE_ADJ_TERMS_DATA,
  payload: data,
});

const setPriceAdjTermsError = (error: PriceAdjTermError): PriceAdjTermsAction => ({
  type: SET_PRICE_ADJ_TERMS_ERROR,
  payload: { error },
});

export const setPriceAdjTermsCurrentCompany = (companyId: string): PriceAdjTermsAction => ({
  type: SET_PRICE_ADJ_TERMS_CURRENT_COMPANY,
  payload: { companyId },
});

export const resetPriceAdjTermsState = (): PriceAdjTermsAction => ({
  type: RESET_PRICE_ADJ_TERMS_STATE,
});

// ASYNC Actions (Thunk Actions)
export const fetchPriceAdjTermsList =
  (companyId?: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(updatePriceAdjTermsStatus("loading"));
      const system = getState().system;
      const token = system.session.token;
      const _companyId = companyId ? companyId : system.session.userDetails.companyId;

      const response = await executeApiCall(token).get(`${url}?companyId=${_companyId}`);
      dispatch(loadPriceAdjTermsData(response.data));
    } catch (err: any) {
      handleError(err, dispatch);
    } finally {
      dispatch(updatePriceAdjTermsStatus("idle"));
    }
  };

export const editPriceAdjTerms =
  (
    currentCompanyId: string,
    id: string,
    terms: PriceAdjTermInput,
    successCb?: () => void
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(updatePriceAdjTermsStatus("loading"));
      const system = getState().system;
      // const { currentCompanyId } = getState().branchState;
      const token = system.session.token;
      const companyId = currentCompanyId ? currentCompanyId : system.session.userDetails.companyId;

      const data = { ...terms, companyId };
      const response = await executeApiCall(token).put(`${url}/${id}`, data);

      if (successResponses.includes(response.status)) {
        dispatch(clearDialog());
        dispatch(updatePriceAdjTermsStatus("success"));
        dispatch(openAlert("Successfully saved!", "success"));

        if (successCb) successCb();
      }
    } catch (err: any) {
      handleError(err, dispatch);
    } finally {
      dispatch(updatePriceAdjTermsStatus("idle"));
    }
  };

export const deletePriceAdjTerms =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(updatePriceAdjTermsStatus("loading"));

      const system = getState().system;
      const token = system.session.token;

      await executeApiCall(token).delete(`${url}/${id}`);

      // show success message
      dispatch(clearDialog());
      dispatch(openAlert("Successfully deleted!", "success"));

      dispatch({ type: DELETE_PRICE_ADJ_TERMS, payload: { id } });
    } catch (err: any) {
      handleError(err, dispatch);
    } finally {
      dispatch(updatePriceAdjTermsStatus("idle"));
    }
  };

const handleError = (err: any, dispatch: any) => {
  let message = "Error";

  if (err?.response?.data) {
    const error = err.response.data.error;
    message = error.message ? error.message.split(": ")[1] : "Error";
  }

  dispatch(updatePriceAdjTermsStatus("failed"));
  dispatch(setPriceAdjTermsError(message));

  // show error message
  dispatch(openAlert(message, "error"));

  dispatch(clearDialog());
};
