import {
  ADD_SOURCEFACTORY,
  SourceFactoryAction,
  SourceFactoryState,
  DELETE_SOURCEFACTORY,
  EDIT_SOURCEFACTORY,
  LOAD_SOURCEFACTORY_DATA,
  RESET_SOURCEFACTORY_STATE,
  SET_SOURCEFACTORY_CURRENT_COMPANY,
  SET_SOURCEFACTORY_ERROR,
  UPDATE_SOURCEFACTORY_STATUS,
} from "./types";

const initialState: SourceFactoryState = {
  status: "idle",
  data: [],
};

const sourceFactoryReducer = (
  state: SourceFactoryState = initialState,
  action: SourceFactoryAction
): SourceFactoryState => {
  switch (action.type) {
    case UPDATE_SOURCEFACTORY_STATUS:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.status !== "failed" ? null : state.error,
      };
    case SET_SOURCEFACTORY_ERROR:
      return { ...state, error: action.payload.error };
    case SET_SOURCEFACTORY_CURRENT_COMPANY:
      return { ...state, currentCompanyId: action.payload.companyId };
    case LOAD_SOURCEFACTORY_DATA:
      return { ...state, data: action.payload, status: "success" };
    case ADD_SOURCEFACTORY:
      return { ...state, data: [action.payload.newBranch, ...state.data] };
    case EDIT_SOURCEFACTORY:
      return {
        ...state,
        data: state.data.map((b) =>
          b.id === action.payload.id ? action.payload.updatedBranch : b
        ),
      };
    case DELETE_SOURCEFACTORY:
      return {
        ...state,
        data: state.data.filter((b) => b.id !== action.payload.id),
      };
    case RESET_SOURCEFACTORY_STATE:
      return initialState;
    default:
      return state;
  }
};

export default sourceFactoryReducer;
