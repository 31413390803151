import { ReactNode } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import { RepnotesContentHeader } from "./RepnotesContentHeader";
import { RepnotesDefaultButton, RepnotesPrimaryButton } from "./RepnotesButton";

interface RepnotesFormShellProps {
  moduleName: string;
  subModuleName: string;
  isSaveBtnShown?: boolean;
  isLoading?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  children?: ReactNode;
}

const RepnotesFormShell = (props: RepnotesFormShellProps) => {
  const { moduleName, subModuleName, isSaveBtnShown, isLoading, onCancel, onSave, children } =
    props;
  return (
    <Box className='repnotes-content'>
      <Box style={{ textAlign: "left", paddingTop: "10px 0px" }}>
        <RepnotesContentHeader moduleName={moduleName} subModule={subModuleName} />
      </Box>
      <Grid container style={{ paddingTop: "20px" }}>
        <Grid
          container
          justify='flex-end'
          style={{ padding: "10px 0", position: "relative", right: -3 }}
        >
          <RepnotesDefaultButton onClick={onCancel}>Cancel</RepnotesDefaultButton>
          {isSaveBtnShown && (
            <RepnotesPrimaryButton className='no-margin-right' onClick={onSave}>
              {isLoading ? (
                <CircularProgress
                  style={{
                    display: "flex",
                    width: 20,
                    height: 20,
                    color: "#fff",
                    padding: 3,
                  }}
                />
              ) : (
                "Save"
              )}
            </RepnotesPrimaryButton>
          )}
        </Grid>
      </Grid>
      <Grid className='repnotes-form' container spacing={1}>
        <Grid container>
          <Grid item xs={1} />
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default RepnotesFormShell;
