import React from "react";
import { useSelector } from "react-redux";

import Box from "@material-ui/core/Box";

import AppTable, { useTableUtils } from "../../common/RepnotesAMITable";
import { LoadingDialog } from "../../common/RepnotesAlerts";
import TermsControlBar from "./TermsControlBar";
import TermsColumns from "./termsColumns";
import { selectPriceAdjTermState } from "../../../store/priceAdjustmentFactors/terms/selectors";
import { useFetchPriceAdjTerms } from "./hooks";
import { usePriceAdjusmentFactorsPermission } from "../../../util/utils";

const TermsList = () => {
  const { sort, sortColumn, sortHandler, search, setSearch, filterBySearch } = useTableUtils({
    initialSortBy: "",
  });

    const { canEdit } = usePriceAdjusmentFactorsPermission();
  useFetchPriceAdjTerms();

  const { status, data } = useSelector(selectPriceAdjTermState);
  const loading = status === "loading";

  const filteredData = search ? filterBySearch(data) : data;

  const sortedData = sort(filteredData, sortColumn.path, sortColumn.order);

  const columns = (canEdit) ? TermsColumns : TermsColumns.slice(0, -1);

  return (
    <>
      <Box className='repnotes-content'>
        <TermsControlBar search={search} onSearch={setSearch} />
        {loading ? (
          <LoadingDialog />
        ) : (
          <AppTable style={{ width: "100%" }}>
            <AppTable.Container stickyHeader={false}>
              <AppTable.Head sortColumn={sortColumn} onSort={sortHandler} columns={columns} />
              <AppTable.Body columns={columns} data={sortedData} />
            </AppTable.Container>
          </AppTable>
        )}
      </Box>
    </>
  );
};

export default TermsList;
