import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";

import RepnotesFormShell from "../../common/RepnotesFormShell";
import {
  selectSourceDeliveryFactorById,
  selectSourceDeliveryFactorState,
} from "../../../store/priceAdjustmentFactors/sourceDeliveryFactor/selectors";
import {
  initialSDFInputValue,
  SourceDeliveryFactorInput,
} from "../../../store/priceAdjustmentFactors/sourceDeliveryFactor/types";
import { mapToOptions, useDialog } from "../../../util/utils";
import { RepnotesInput } from "../../common/RepnotesInput";
import PriceAdjustmentField from "../../common/PriceAdjustmentField";
import { FormRadioGroup } from "../../fieldsManagement/shared/FormLib";
import { deliveryAreaToPriceMap, priceAdjDeliveryAreaOptions, priceAdjustmentTypeOptions } from "../../../util/constants";
import InputContainerForForm from "../../common/InputContainerForForm";
import { appColors } from "../../fieldsManagement/shared/constants";
import { RepnotesDialog } from "../../common/RepnotesAlerts";
import { openAlert } from "../../../store/alert/actions";
import {
  createSourceDeliveryFactor,
  editSourceDeliveryFactor,
} from "../../../store/priceAdjustmentFactors/sourceDeliveryFactor/actions";
import { selectSourceFactoryDataList } from "../../../store/listManagement/sourceFactory/selectors";
import { useFetchSourceDeliveryFactor } from "./hooks";

interface IParams {
  id: string;
}

const SourceDeliveryFactorForm = () => {
  const history = useHistory();
  const { status, currentCompanyId } = useSelector(selectSourceDeliveryFactorState);
  useFetchSourceDeliveryFactor()

  const { id } = useParams<IParams>();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState<SourceDeliveryFactorInput>(initialSDFInputValue);
  const [errorObj, setErrorObj] = useState<Record<string, boolean>>({});
  const sdfToEdit = useSelector(selectSourceDeliveryFactorById(id));
  const { dialog, closeDialog, openConfirmSaveDialog } = useDialog();

  const isSaveBtnShown = true; // TODO: update with permission consideration
  const isLoading = status === "loading";
  const isAdding = id === "new";

  useEffect(() => {
    if (isAdding || !sdfToEdit) return;
    setFormData({ ...sdfToEdit });
  }, [sdfToEdit, isAdding]);

  const sourceFactoryList = useSelector(selectSourceFactoryDataList);
  const sourceFactoryOptions = mapToOptions(sourceFactoryList);

  const { deliveryArea } = formData;
  useEffect(() => {
    const currentPriceAdjValue = deliveryAreaToPriceMap[deliveryArea] || 0;
    setFormData((prev) => ({ ...prev, priceAdjustment: currentPriceAdjValue }));
  }, [deliveryArea]);

  const handleSelectChange =
    (key: keyof SourceDeliveryFactorInput) =>
    (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      setErrorObj({});
      const value = e.target.value;
      setFormData((prev) => ({ ...prev, [key]: value }));
    };

  const handlePriceChange = (value: number) =>
    setFormData((prev) => ({ ...prev, priceAdjustment: value }));

  const handlePriceTypeChange = (value: "amount" | "percentage") =>
    setFormData((prev) => ({ ...prev, priceAdjustmentType: value }));

  const handleCancel = () => history.push("/source-plus-delivery-to-factor");

  const handleSave = () => {
    const errors = Object.keys(formData).reduce<Record<string, boolean>>(
      (mapped, curr) => ({
        ...mapped,
        [curr]: formData[curr as keyof typeof formData] === "",
      }),
      {}
    );

    setErrorObj(errors);

    // if (data.some((d) => d.make === formData.make) && isAdding) {
    //   return dispatch(
    //     openAlert("The selected make already has a Source + Delivery To Factor.", "error")
    //   );
    // }

    // if (data.some((d) => d.make === formData.make && d.make !== sdfToEdit?.make) && !isAdding) {
    //   return dispatch(
    //     openAlert("The selected make already has a Source + Delivery To Factor.", "error")
    //   );
    // }

    if (Object.keys(errors).some((key) => errors[key as string] === true)) {
      return dispatch(openAlert("Please check required field.", "error"));
    }
    openConfirmSaveDialog("this Source + Delivery Factor");
  };

  const handleDialogSaveClick = () => {
    const successCb = () => history.push("/source-plus-delivery-to-factor");
    if (!currentCompanyId) return;

    if (isAdding) dispatch(createSourceDeliveryFactor(currentCompanyId, formData, successCb));
    else dispatch(editSourceDeliveryFactor(currentCompanyId, id, formData, successCb));
  };

  return (
    <>
      <RepnotesFormShell
        moduleName='Price Adjustment Factors'
        subModuleName='Source + Delivery To Factor'
        isSaveBtnShown={isSaveBtnShown}
        isLoading={isLoading}
        onCancel={handleCancel}
        onSave={handleSave}
      >
        <Grid item xs={7}>
          <RepnotesInput
            id='sourceFactory'
            type='select'
            placeholder='Source Factory'
            label='Source Factory'
            labelPosition='left'
            disabled={false}
            error={errorObj["sourceFactory"]}
            value={formData.sourceFactory}
            options={sourceFactoryOptions.options}
            onSelectChange={handleSelectChange("sourceFactory")}
          />
          <RepnotesInput
            id='deliveryArea'
            type='select'
            placeholder='Delivery Area'
            label='Delivery Area'
            labelPosition='left'
            disabled={false}
            error={errorObj["deliverTo"]}
            value={formData.deliveryArea}
            options={priceAdjDeliveryAreaOptions}
            onSelectChange={handleSelectChange("deliveryArea")}
          />
          <InputContainerForForm label='Price Adjustment Type'>
            <FormRadioGroup
              options={priceAdjustmentTypeOptions}
              color={appColors.darkViolet}
              row
              id='priceAdjustmentType'
              onChange={(e, v) => handlePriceTypeChange(v as "amount" | "percentage")}
              value={formData.priceAdjustmentType}
            />
          </InputContainerForForm>
          <PriceAdjustmentField
            id='priceAdjustment'
            label='Price Adjustment'
            value={formData.priceAdjustment}
            onChange={handlePriceChange}
            priceType={formData.priceAdjustmentType}
            error={errorObj["priceAdjustment"]}
          />
        </Grid>
      </RepnotesFormShell>
      <RepnotesDialog
        label={dialog.dialogLabel}
        open={dialog.dialogOpen}
        severity={dialog.dialogType}
        onClick={handleDialogSaveClick}
        onClear={closeDialog}
      />
    </>
  );
};

export default SourceDeliveryFactorForm;
