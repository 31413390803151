import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import { RepnotesContentHeader } from "../../common/RepnotesContentHeader";
import { RepnotesDefaultButton, RepnotesPrimaryButton } from "../../common/RepnotesButton";
import { RepnotesInput } from "../../common/RepnotesInput";

import { useDialog } from "../../../util/utils";
import PriceAdjustmentField from "../../common/PriceAdjustmentField";
import { FormRadioGroup } from "../../fieldsManagement/shared/FormLib";
import { priceAdjustmentTypeOptions } from "../../../util/constants";
import InputContainerForForm from "../../common/InputContainerForForm";
import { appColors } from "../../fieldsManagement/shared/constants";
import { RepnotesDialog } from "../../common/RepnotesAlerts";
import {
  selectPriceAdjTermById,
  selectPriceAdjTermState,
} from "../../../store/priceAdjustmentFactors/terms/selectors";
import {
  initialTermsInputValue,
  PriceAdjTermInput,
} from "../../../store/priceAdjustmentFactors/terms/types";
import { editPriceAdjTerms } from "../../../store/priceAdjustmentFactors/terms/actions";

interface IParams {
  id: string;
}

const TermsForm = () => {
  const history = useHistory();
  const { status, currentCompanyId } = useSelector(selectPriceAdjTermState);
  const { id } = useParams<IParams>();
  const dispatch = useDispatch();

  const [terms, setTerms] = useState<PriceAdjTermInput>(initialTermsInputValue);
  const termsToEdit = useSelector(selectPriceAdjTermById(id));

  const { dialog, closeDialog, openConfirmSaveDialog } = useDialog();

  const isSaveBtnShown = true; // TODO: update with permission consideration
  const isLoading = status === "loading";

  useEffect(() => {
    if (!termsToEdit) return;
    setTerms({ ...termsToEdit });
  }, [termsToEdit]);

  const handlePriceChange = (value: number) =>
    setTerms((prev) => ({ ...prev, priceAdjustment: value }));

  const handlePriceTypeChange = (value: "amount" | "percentage") =>
    setTerms((prev) => ({ ...prev, priceAdjustmentType: value }));

  const handleCancel = () => history.push("/price-adj-terms");

  const handleSave = () => {
    openConfirmSaveDialog("this price adjustment term");
  };

  const handleDialogSaveClick = () => {
    const successCb = () => history.push("/price-adj-terms");
    if (!currentCompanyId) return;
    else dispatch(editPriceAdjTerms(currentCompanyId, id, terms, successCb));
  };

  return (
    <>
      <Box className='repnotes-content'>
        <Box style={{ textAlign: "left", paddingTop: "10px 0px" }}>
          <RepnotesContentHeader moduleName='Price Adjustment Factors' subModule='Terms' />
        </Box>
        <Grid container style={{ paddingTop: "20px" }}>
          <Grid
            container
            justify='flex-end'
            style={{ padding: "10px 0", position: "relative", right: -3 }}
          >
            <RepnotesDefaultButton onClick={handleCancel}>Cancel</RepnotesDefaultButton>
            {isSaveBtnShown && (
              <RepnotesPrimaryButton className='no-margin-right' onClick={handleSave}>
                {isLoading ? (
                  <CircularProgress
                    style={{
                      display: "flex",
                      width: 20,
                      height: 20,
                      color: "#fff",
                      padding: 3,
                    }}
                  />
                ) : (
                  "Save"
                )}
              </RepnotesPrimaryButton>
            )}
          </Grid>
        </Grid>
        <Grid className='repnotes-form' container spacing={1}>
          <Grid container>
            <Grid item xs={1} />
            <Grid item xs={7}>
              <RepnotesInput
                id='terms'
                type='text'
                placeholder='Terms'
                label='Terms'
                labelPosition='left'
                disabled
                value={terms.terms}
              />
              <InputContainerForForm label='Price Adjustment Type'>
                <FormRadioGroup
                  options={priceAdjustmentTypeOptions}
                  color={appColors.darkViolet}
                  row
                  id='priceAdjustmentType'
                  onChange={(e, v) => handlePriceTypeChange(v as "amount" | "percentage")}
                  value={terms.priceAdjustmentType}
                />
              </InputContainerForForm>
              <PriceAdjustmentField
                id='priceAdjustment'
                label='Price Adjustment'
                value={terms.priceAdjustment}
                onChange={handlePriceChange}
                priceType={terms.priceAdjustmentType}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <RepnotesDialog
        label={dialog.dialogLabel}
        open={dialog.dialogOpen}
        severity={dialog.dialogType}
        onClick={handleDialogSaveClick}
        onClear={closeDialog}
      />
    </>
  );
};

export default TermsForm;
