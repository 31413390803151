import Box from "@material-ui/core/Box";
import { COGS } from "../../../store/priceAdjustmentFactors/cogs/types";
import { TableColumn } from "../../../util/constants";
import {
  formatDateForTable,
  useDialog,
  usePriceAdjusmentFactorsPermission,
} from "../../../util/utils";
import { RepnotesActionButton } from "../../common/RepnotesButton";

const ActionButton = ({ itemData }: { itemData: COGS }) => {
  const { openDeleteDialog } = useDialog();
  const { canEdit, canDelete } = usePriceAdjusmentFactorsPermission();

  return (
    <Box display='flex' flexDirection='row' height={35}>
      {(canEdit) && (
        <RepnotesActionButton link='cogs' docId={itemData.id} type='edit' />
      )}
      {(canDelete) && (
        <RepnotesActionButton
          onClick={() => openDeleteDialog("This COGS data", itemData.id)}
          type='delete'
        />
      )}
    </Box>
  );
};

const COGSColumns: TableColumn[] = [
  {
    id: "make",
    label: "Make",
    property: "make",
    sortable: true,
  },
  {
    id: "brand",
    label: "Brand",
    property: "brand",
    sortable: true,
  },
  {
    id: "packaging",
    label: "Packaging",
    property: "packaging",
    sortable: true,
  },
  {
    id: "type",
    label: "Type",
    property: "type",
    sortable: true,
  },

  {
    id: "productCode",
    label: "Product Code",
    property: "productCode",
    sortable: true,
  },
  {
    id: "sourceFactory",
    label: "Source Factory",
    property: "sourceFactory",
    sortable: true,
  },
  {
    id: "priceAdjustment",
    label: "Price Adjustment",
    property: "priceAdjustment",
    sortable: true,
    removePadding: true,
    content: (cogs: COGS) =>
      cogs.priceAdjustmentType === "percentage" ? cogs.priceAdjustment + "%" : cogs.priceAdjustment,
  },
  {
    id: "dateCreated",
    label: "Date Created",
    property: "dateCreated",
    sortable: true,
    removePadding: true,
    content: (cogs: COGS) => formatDateForTable(cogs.dateCreated),
  },
  {
    id: "dateUpdated",
    label: "Date Updated",
    property: "dateUpdated",
    sortable: true,
    removePadding: true,
    content: (cogs: COGS) => formatDateForTable(cogs.dateUpdated),
  },
  {
    id: "action",
    label: "Actions",
    key: "cogs-actions",
    sortable: false,
    content: (cogs: COGS) => <ActionButton itemData={cogs} />,
  },
];

export default COGSColumns;
