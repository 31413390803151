// State Interfaces
export interface SourceFactory {
  id: string;
  companyId: string;
  name: string;
  isActive: boolean;
  dateCreated: string;
  dateUpdated: string;
}

export type SourceFactoryStatus = "idle" | "success" | "loading" | "failed";
export type SourceFactoryError = string | null;
export type SourceFactoryInput = Pick<SourceFactory, "name" | "isActive">;

export const initialSFIBnputValue: SourceFactoryInput = {
  name: "",
  isActive: true
};

export interface SourceFactoryState {
  status: SourceFactoryStatus;
  data: SourceFactory[];
  error?: SourceFactoryError;
  currentCompanyId?: string;
}

// Action Types
export const LOAD_SOURCEFACTORY_DATA = "sourcefactory/fetch";
export const ADD_SOURCEFACTORY = "sourcefactory/add";
export const EDIT_SOURCEFACTORY = "sourcefactory/edit";
export const DELETE_SOURCEFACTORY = "sourcefactory/delete";
export const UPDATE_SOURCEFACTORY_STATUS = "sourcefactory/status";
export const SET_SOURCEFACTORY_ERROR = "sourcefactory/error";
export const SET_SOURCEFACTORY_CURRENT_COMPANY = "sourcefactory/company";
export const RESET_SOURCEFACTORY_STATE = "sourcefactory/reset";

export interface LoadSourceFactoryDataAction {
  type: typeof LOAD_SOURCEFACTORY_DATA;
  payload: SourceFactory[];
}

export interface SetSourceFactoryCurrentCompanyAction {
  type: typeof SET_SOURCEFACTORY_CURRENT_COMPANY;
  payload: { companyId: string };
}

export interface AddSourceFactoryAction {
  type: typeof ADD_SOURCEFACTORY;
  payload: {
    newBranch: SourceFactory;
  };
}

export interface EditSourceFactoryAction {
  type: typeof EDIT_SOURCEFACTORY;
  payload: {
    id: string;
    updatedBranch: SourceFactory;
  };
}

export interface DeleteSourceFactoryAction {
  type: typeof DELETE_SOURCEFACTORY;
  payload: { id: string };
}

export interface UpdateSourceFactoryStatusAction {
  type: typeof UPDATE_SOURCEFACTORY_STATUS;
  payload: { status: SourceFactoryStatus };
}

export interface SetSourceFactoryErrorAction {
  type: typeof SET_SOURCEFACTORY_ERROR;
  payload: { error?: SourceFactoryError };
}

export interface ResetSourceFactoryAction {
  type: typeof RESET_SOURCEFACTORY_STATE;
}

export type SourceFactoryAction =
  | LoadSourceFactoryDataAction
  | AddSourceFactoryAction
  | EditSourceFactoryAction
  | DeleteSourceFactoryAction
  | UpdateSourceFactoryStatusAction
  | SetSourceFactoryErrorAction
  | SetSourceFactoryCurrentCompanyAction
  | ResetSourceFactoryAction;
