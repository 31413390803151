import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "@material-ui/core/Box";

import AppTable, { useTableUtils } from "../../common/RepnotesAMITable";
import { LoadingDialog, RepnotesDialog } from "../../common/RepnotesAlerts";
import { useDialog, usePriceAdjusmentFactorsPermission } from "../../../util/utils";
import SourcePlusDeliverControlBar from "./sourcePlusDeliveryControlBar";
import {
  selectSourceDeliveryFactorState,
  selectTransformedSourceDeliveryFactorData,
} from "../../../store/priceAdjustmentFactors/sourceDeliveryFactor/selectors";
import SourceDeliveryFactorColumns from "./sourceDeliveryFactorColumns";
import { deleteSourceDeliveryFactor } from "../../../store/priceAdjustmentFactors/sourceDeliveryFactor/actions";
import { useFetchSourceDeliveryFactor } from "./hooks";

const SourcePlusDeliveryToFactorList = () => {
  const {
    sort,
    paginate,
    sortColumn,
    sortHandler,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    search,
    setSearch,
    filterBySearch,
  } = useTableUtils({ initialRowsPerPage: 10 });

  const dispatch = useDispatch();
  const { status } = useSelector(selectSourceDeliveryFactorState);
  const data = useSelector(selectTransformedSourceDeliveryFactorData);
  const { canEdit, canDelete } = usePriceAdjusmentFactorsPermission();
  const loading = status === "loading";
  const { dialog, closeDialog } = useDialog();

  useFetchSourceDeliveryFactor();

  const filteredData = search ? filterBySearch(data) : data;

  const sortedData = sort(filteredData, sortColumn.path, sortColumn.order);

  const paginatedData = paginate(sortedData, page, rowsPerPage);

  const columns = (canEdit || canDelete) ? SourceDeliveryFactorColumns : SourceDeliveryFactorColumns.slice(0, -1);

  const handleDelete = () => {
    dispatch(deleteSourceDeliveryFactor(dialog.docId as string));
  };

  return (
    <>
      <Box className="repnotes-content">
        <SourcePlusDeliverControlBar search={search} onSearch={setSearch} />
        {loading ? (
          <LoadingDialog />
        ) : (
          <AppTable style={{ width: "100%" }}>
            <AppTable.Container stickyHeader={false}>
              <AppTable.Head
                sortColumn={sortColumn}
                onSort={sortHandler}
                columns={columns}
              />
              <AppTable.Body columns={columns} data={paginatedData} />
            </AppTable.Container>
            <AppTable.Pagination
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              component="div"
              count={sortedData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </AppTable>
        )}
      </Box>
      <RepnotesDialog
        label={dialog.dialogLabel}
        open={dialog.dialogOpen}
        severity={dialog.dialogType}
        onClick={handleDelete}
        onClear={closeDialog}
      />
    </>
  );
};

export default SourcePlusDeliveryToFactorList;
