import {
  LOAD_PRICE_ADJ_TERMS_DATA,
  RESET_PRICE_ADJ_TERMS_STATE,
  PriceAdjTermsAction,
  PriceAdjTermState,
  SET_PRICE_ADJ_TERMS_CURRENT_COMPANY,
  SET_PRICE_ADJ_TERMS_ERROR,
  UPDATE_PRICE_ADJ_TERMS_STATUS,
} from "./types";

const initialState: PriceAdjTermState = {
  status: "idle",
  data: [],
  error: null,
};

const priceAdjTermsReducer = (
  state: PriceAdjTermState = initialState,
  action: PriceAdjTermsAction
): PriceAdjTermState => {
  switch (action.type) {
    case UPDATE_PRICE_ADJ_TERMS_STATUS:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.status !== "failed" ? null : state.error,
      };
    case SET_PRICE_ADJ_TERMS_ERROR:
      return { ...state, error: action.payload.error };
    case SET_PRICE_ADJ_TERMS_CURRENT_COMPANY:
      return { ...state, currentCompanyId: action.payload.companyId };
    case LOAD_PRICE_ADJ_TERMS_DATA:
      return { ...state, data: action.payload, status: "success" };
    case RESET_PRICE_ADJ_TERMS_STATE:
      return initialState;
    default:
      return state;
  }
};

export default priceAdjTermsReducer;
