import Box from "@material-ui/core/Box";
import { SourceDeliveryFactor } from "../../../store/priceAdjustmentFactors/sourceDeliveryFactor/types";
import { TableColumn } from "../../../util/constants";
import { formatDateForTable, useDialog, usePriceAdjusmentFactorsPermission } from "../../../util/utils";
import { RepnotesActionButton } from "../../common/RepnotesButton";

const ActionButton = ({ itemData }: { itemData: SourceDeliveryFactor }) => {
  const { openDeleteDialog } = useDialog();
  const { canEdit, canDelete } = usePriceAdjusmentFactorsPermission();

  return (
    <Box display='flex' flexDirection='row' height={35}>
      {(canEdit) && <RepnotesActionButton link='source-plus-delivery-to-factor' docId={itemData.id} type='edit' />}
      {(canDelete) &&
      <RepnotesActionButton
        onClick={() => openDeleteDialog("This Source Delivery Factor data", itemData.id)}
        type='delete'
      />}
    </Box>
  );
};

const SourceDeliveryFactorColumns: TableColumn[] = [
  {
    id: "sourceFactory",
    label: "Source Factory",
    property: "sourceFactory",
    sortable: true,
  },
  {
    id: "deliveryArea",
    label: "Deliver To",
    property: "deliveryArea",
    sortable: true,
  },
  {
    id: "priceAdjustment",
    label: "Price Adjustment",
    property: "priceAdjustment",
    sortable: true,
    removePadding: true,
    content: (item: SourceDeliveryFactor) =>
      item.priceAdjustmentType === "percentage" ? item.priceAdjustment + "%" : item.priceAdjustment,
  },
  {
    id: "dateCreated",
    label: "Date Created",
    property: "dateCreated",
    sortable: true,
    removePadding: true,
    content: (item: SourceDeliveryFactor) => formatDateForTable(item.dateCreated),
  },
  {
    id: "dateUpdated",
    label: "Date Updated",
    property: "dateUpdated",
    sortable: true,
    removePadding: true,
    content: (item: SourceDeliveryFactor) => formatDateForTable(item.dateUpdated),
  },
  {
    id: "action",
    label: "Actions",
    key: "source-delivery-factor-actions",
    sortable: false,
    content: (item: SourceDeliveryFactor) => <ActionButton itemData={item} />,
  },
];

export default SourceDeliveryFactorColumns;
