import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import { RepnotesContentHeader } from "../../common/RepnotesContentHeader";
import { RepnotesInput } from "../../common/RepnotesInput";
import useCompany from "../../../util/utils";

import { selectPriceAdjTermState } from "../../../store/priceAdjustmentFactors/terms/selectors";
import { setPriceAdjTermsCurrentCompany } from "../../../store/priceAdjustmentFactors/terms/actions";

interface Props {
  search: string;
  onSearch: React.Dispatch<React.SetStateAction<string>>;
}

const TermsControlBar = (props: Props) => {
  const { search, onSearch } = props;
  const dispatch = useDispatch();

  const { currentCompanyId } = useSelector(selectPriceAdjTermState);

  const { isUserSuper, companySelectOptions } = useCompany();

  const handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement | any>) => {
    const companyName = e.target.value;
    dispatch(setPriceAdjTermsCurrentCompany(companyName));
  };

  const handleSearchClear = () => onSearch("");

  return (
    <>
      <Box style={{ textAlign: "left", paddingTop: "10px 0px" }}>
        <RepnotesContentHeader moduleName='Price Adjustment Factors' subModule='Terms' />
      </Box>
      <Grid container style={{ paddingTop: "20px" }}>
        {isUserSuper && (
          <Grid item xs={2}>
            <RepnotesInput
              id='repnotes-list-price-adj-terms'
              type='select'
              label='Company Name'
              labelPosition='top'
              options={companySelectOptions}
              value={currentCompanyId}
              onSelectChange={handleCompanyChange}
            />
          </Grid>
        )}
        <Box alignSelf='end' ml='auto' display='flex'>
          <Box width='130px'>
            <RepnotesInput
              id='search'
              type='search'
              placeholder='Search'
              value={search}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onSearch(e.target.value);
              }}
              onClear={handleSearchClear}
            />
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default TermsControlBar;
