import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPriceAdjTermsList } from "../../../store/priceAdjustmentFactors/terms/actions";
import { selectPriceAdjTermState } from "../../../store/priceAdjustmentFactors/terms/selectors";

import { selectSystemState } from "../../../store/system/actions";

export function useListPermission() {
  const { session } = useSelector(selectSystemState);
  const { modules } = session;
  // TODO: Change to Price Adj Permissions
  const canEdit = modules.listManagement.edit;
  const canDelete = modules.listManagement.delete;
  const canAdd = modules.listManagement.add;
  const canView = modules.listManagement.view;

  return { canEdit, canDelete, canAdd, canView };
}

export function useFetchPriceAdjTerms() {
  const dispatch = useDispatch();
  const { status, error, data, currentCompanyId } = useSelector(selectPriceAdjTermState);

  useEffect(() => {
    if (!currentCompanyId) return;

    dispatch(fetchPriceAdjTermsList(currentCompanyId));
  }, [currentCompanyId, dispatch]);

  return { loading: status === "loading", error, data };
}
