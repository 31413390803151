import {
  ADD_SOURCE_DELIVERY_FACTOR,
  SourceDeliveryFactorAction,
  SourceDeliveryFactorState,
  DELETE_SOURCE_DELIVERY_FACTOR,
  EDIT_SOURCE_DELIVERY_FACTOR,
  LOAD_SOURCE_DELIVERY_FACTOR_DATA,
  RESET_SOURCE_DELIVERY_FACTOR_STATE,
  SET_SOURCE_DELIVERY_FACTOR_CURRENT_COMPANY,
  SET_SOURCE_DELIVERY_FACTOR_ERROR,
  UPDATE_SOURCE_DELIVERY_FACTOR_STATUS,
} from "./types";

const initialState: SourceDeliveryFactorState = {
  status: "idle",
  data: [],
  error: null,
};

const sourceDeliveryFactorReducer = (
  state: SourceDeliveryFactorState = initialState,
  action: SourceDeliveryFactorAction
): SourceDeliveryFactorState => {
  switch (action.type) {
    case UPDATE_SOURCE_DELIVERY_FACTOR_STATUS:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.status !== "failed" ? null : state.error,
      };
    case SET_SOURCE_DELIVERY_FACTOR_ERROR:
      return { ...state, error: action.payload.error };
    case SET_SOURCE_DELIVERY_FACTOR_CURRENT_COMPANY:
      return { ...state, currentCompanyId: action.payload.companyId };
    case LOAD_SOURCE_DELIVERY_FACTOR_DATA:
      return { ...state, data: action.payload, status: "success" };
    case ADD_SOURCE_DELIVERY_FACTOR:
      return { ...state, data: [action.payload.newSourceDeliveryFactor, ...state.data] };
    case EDIT_SOURCE_DELIVERY_FACTOR:
      return {
        ...state,
        data: state.data.map((b) =>
          b.id === action.payload.id ? action.payload.updatedSourceDeliveryFactor : b
        ),
      };
    case DELETE_SOURCE_DELIVERY_FACTOR:
      return {
        ...state,
        data: state.data.filter((b) => b.id !== action.payload.id),
      };
    case RESET_SOURCE_DELIVERY_FACTOR_STATE:
      return initialState;
    default:
      return state;
  }
};

export default sourceDeliveryFactorReducer;
