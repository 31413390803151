import { AppState } from "../../index";

export const selectSourceFactoryState = (state: AppState) =>
  state.sourceFactoryState;

export const selectSourceFactoryById = (id: string) => (state: AppState) =>
  state.sourceFactoryState.data.find((b) => b.id === id);

export const selectSourceFactoryMap = (state: AppState) => {
  return state.sourceFactoryState.data.reduce<Record<string, string>>(
    (mapped, curr) => ({ ...mapped, [curr.id]: curr.name }),
    {}
  );
};

export const selectTransformedSourceFactoryData = (state: AppState) =>
  state.sourceFactoryState.data.map((item) => ({
    ...item,
  }));

export const selectSourceFactoryDataList = (state: AppState) =>
  state.sourceFactoryState.data.filter((t) => t.isActive === true);
