import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMakeList } from "../../../store/listManagement/make/actions";
import { fetchSourceFactoryList } from "../../../store/listManagement/sourceFactory/actions";

import { fetchCOGSList } from "../../../store/priceAdjustmentFactors/cogs/actions";
import { selectCOGSState } from "../../../store/priceAdjustmentFactors/cogs/selectors";
import { selectSystemState } from "../../../store/system/actions";

export function useListPermission() {
  const { session } = useSelector(selectSystemState);
  const { modules } = session;
  // TODO: Change to Price Adj Permissions
  const canEdit = modules.listManagement.edit;
  const canDelete = modules.listManagement.delete;
  const canAdd = modules.listManagement.add;
  const canView = modules.listManagement.view;

  return { canEdit, canDelete, canAdd, canView };
}

export function useFetchCOGS() {
  const dispatch = useDispatch();
  const system = useSelector(selectSystemState);
  const { status, error, data, currentCompanyId } =
    useSelector(selectCOGSState);

  useEffect(() => {
    if (!currentCompanyId) return;

    dispatch(getMakeList(system, currentCompanyId));
  }, [currentCompanyId, dispatch, system]);

  useEffect(() => {
    if (!currentCompanyId) return;

    dispatch(fetchSourceFactoryList(currentCompanyId));
  }, [currentCompanyId, dispatch]);

  useEffect(() => {
    if (!currentCompanyId) return;

    dispatch(fetchCOGSList(currentCompanyId));
  }, [currentCompanyId, dispatch]);

  return { loading: status === "loading", error, data };
}
