import { AppState } from "../../index";

export const selectSourceDeliveryFactorState = (state: AppState) => state.sourceDeliveryFactorState;

export const selectSourceDeliveryFactorById = (id: string) => (state: AppState) =>
  state.sourceDeliveryFactorState.data.find((b) => b.id === id);

export const selectTransformedSourceDeliveryFactorData = (state: AppState) =>
  state.sourceDeliveryFactorState.data.map((item) => ({
    ...item,
    sourceFactory: state.sourceFactoryState.data.find((s) => s.id === item.sourceFactory)?.name,
  }));
